import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react'
import superagent from 'superagent'
import classNames from 'classnames'
import Select from 'react-select'
import Datepicker from 'react-tailwindcss-datepicker'
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types'
import * as yup from 'yup'
import { ResultApi, ParamsData } from '../../helper'

interface IOption {
  value: string
  label: string
}

export function AddCert(props) {
  const [bengkelId, setBengkelId] = useState<string>()
  const [bengkelName, setBengkelName] = useState<string>()
  const [openDialogAddCert, setOpenDialogAddCert] = useState(false)
  const [options, setOptions] = useState<Array<IOption>>([])
  const [dateValue, setDateValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [form, setForm] = useState({
    name: {
      value: '',
      error: false,
      errorText: '',
    },
    nameTtd: {
      value: '',
      error: false,
      errorText: '',
    },
    cert: {
      value: {},
      error: false,
      errorText: '',
    },
  })

  const handleOpenDialogDelete = tp => {
    if (!tp) {
      tp = 'cancel'
    }
    let a = !openDialogAddCert
    if (!a) {
      if (props.onClose != undefined) {
        props.onClose(tp)
      }
    }
    setOpenDialogAddCert(a)
  }

  const onAdd = () => {
    onsubmit()
  }

  useEffect(() => {
    if (props.show != undefined) {
      if (props.show) {
        loadDataCert()
      }
      setOpenDialogAddCert(props.show)
    }
  }, [props.show])

  useEffect(() => {
    if (props.bengkelName != undefined) {
      setBengkelName(props.bengkelName)
      setForm(prevState => ({
        ...prevState,
        name: {
          ...prevState.name,
          value: props.bengkelName,
          error: false,
          errorText: '',
        },
      }))
    }
  }, [props.bengkelName])

  useEffect(() => {
    if (props.bengkelId != undefined) {
      setBengkelId(props.bengkelId)
    }
  }, [props.bengkelId])

  const loadDataCert = () => {
    let url = '/api/api/certificates/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/certificates/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          //setData(body.data)
          let dt = body.data
          let dta: Array<IOption> = []
          for (let i = 0; i < dt.length; i++) {
            dta.push({
              value: dt[i]._id,
              label: dt[i].name,
            })
          }
          setOptions(dta)
        } else {
          //alert('Submit failed');
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      name: {
        ...prevState.name,
        value: e.target.value,
        error: false,
        errorText: '',
      },
    }))
  }

  const onNameTtdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      nameTtd: {
        ...prevState.nameTtd,
        value: e.target.value,
        error: false,
        errorText: '',
      },
    }))
  }

  const handleDateValueChange = newValue => {
    console.log('newValue:', newValue)
    setDateValue(newValue)
  }

  const onsubmit = () => {
    const schema = yup.object().shape({
      name: yup.string().min(4).required(),
      nameTtd: yup.string().min(4).required(),
      bengkelId: yup.string().min(4).required(),
      cert: yup
        .object()
        .shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
        .required(),
      startDate: yup.date().required(),
      endDate: yup.date().required(),
    })
    schema
      .validate({
        name: form.name.value,
        bengkelId: bengkelId,
        nameTtd: form.nameTtd.value,
        cert: form.cert.value,
        startDate: new Date(dateValue?.startDate as Date),
        endDate: new Date(dateValue?.endDate as Date),
      })
      .then(async val => {
        let s: Partial<any> = {
          id: val.bengkelId,
          name: val.name,
          nameTtd: val.nameTtd,
          cert: val.cert,
          startDate: val.startDate,
          endDate: val.endDate,
        }

        let url = '/api/api/bengkel/certificates/insert'
        if (process.env.NODE_ENV === 'production') {
          url = '/api/bengkel/certificates/insert'
        }
        superagent
          .post(url)
          .send(s)
          .then(res => {
            const body = res.body as ResultApi
            if (body.result == 200) {
              handleOpenDialogDelete('ok')
            } else {
              //alert('Submit failed');
            }
          })
          .catch(err => {
            console.error(err)
          })
      })
      .catch(function (err) {
        console.error(err)
        if (err.path) {
          if (err.path == 'name') {
            setForm(prevState => ({
              ...prevState,
              name: {
                ...prevState.name,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'nameTtd') {
            setForm(prevState => ({
              ...prevState,
              nameTtd: {
                ...prevState.nameTtd,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'cert.label') {
            setForm(prevState => ({
              ...prevState,
              cert: {
                ...prevState.cert,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'cert.value') {
            setForm(prevState => ({
              ...prevState,
              cert: {
                ...prevState.cert,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const handleCertChange = (value, actionMeta) => {
    setForm(prevState => ({
      ...prevState,
      cert: {
        ...prevState.cert,
        value: value,
        error: false,
        errorText: '',
      },
    }))
  }

  return (
    <Dialog
      open={openDialogAddCert}
      handler={handleOpenDialogDelete}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Add Cert</DialogHeader>
      <DialogBody divider>
        <div className="flex flex-col w-full p-4">
          <div className="w-full">
            <Input
              onChange={onNameChange}
              label="Course Title"
              value={form.name.value}
              error={form.name.error}
            />
            <div
              className={classNames(
                { hidden: !form.name.error },
                'text-xs text-red-500 p-1'
              )}
            >
              {form.name.errorText}
            </div>
          </div>

          <div className="w-full mt-4">
            <Input
              onChange={onNameTtdChange}
              label="Nama Tanda Tangan"
              value={form.nameTtd.value}
              error={form.nameTtd.error}
            />
            <div
              className={classNames(
                { hidden: !form.nameTtd.error },
                'text-xs text-red-500 p-1'
              )}
            >
              {form.nameTtd.errorText}
            </div>
          </div>

          <div className="flex flex-col w-full mt-4 mb-2">
            <div className="text-xs text-gray-600">Cert</div>
            <Select
              onChange={handleCertChange}
              isClearable={true}
              options={options}
            />
            <div
              className={classNames(
                { hidden: !form.cert.error },
                'text-xs text-red-500 p-1'
              )}
            >
              {form.cert.errorText}
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="text-xs">Tanggal</div>
            <div
              className={classNames(`mt-1 block w-full bg-white border border-gray-300 rounded-md md:text-sm text-5xl shadow-sm placeholder-gray-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                    invalid:border-pink-500 invalid:text-pink-600
                    focus:invalid:border-pink-500 focus:invalid:ring-pink-500`)}
            >
              <Datepicker
                useRange={false}
                asSingle={true}
                value={dateValue}
                displayFormat={'dddd, D MMMM, YYYY'}
                onChange={handleDateValueChange}
              />
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={() => handleOpenDialogDelete('cancel')}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button variant="gradient" color="blue" onClick={onAdd}>
          <span>Add Cert</span>
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
