import React, { useEffect, useState } from 'react'
import { Input, Button, CheckBox, DialogModal } from '../../components'
import img_avatar from '../../assets/images/userprofile.png'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import * as yup from 'yup'
import superagent from 'superagent'
import { ResultApi, ParamsData } from '../../helper'

export function RegisterPage() {
  const [loading, setLoading] = useState(false)
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const [textShowHidePassword, setTextShowHidePassword] = useState('SHOW')
  const [typePassword, setTypePassword] = useState('password')
  const [showOtp, setShowOtp] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [disableEmailOtp, setDisableEmailOtp] = useState(false)
  const [ketentuanPenggunaShow, setKetentuanPenggunaShow] = useState(false)
  const [kebijakanPrivasiShow, setKebijakanPrivasiShow] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(true)

  const [form, setForm] = useState({
    name: {
      value: '',
      error: false,
      errorText: '',
    },
    email: {
      value: '',
      error: false,
      errorText: '',
    },
    password: {
      value: '',
      error: false,
      errorText: '',
    },
  })

  const onStateChange = () => {
    if (!form.name.error && !form.email.error && !form.password.error) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }

  useEffect(() => {
    if (registerSuccess) {
      setTimeout(() => {
        location.href = '/auth/login'
      }, 5000)
    }
  }, [registerSuccess])

  useEffect(() => {
    onStateChange()
  }, [form])

  const onNameChange = (name: string, value: string) => {
    const schema = yup.object().shape({
      name: yup.string().min(2).required(),
    })
    schema
      .validate({ name: value })
      .then(val => {
        setForm(prevState => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: val.name,
            error: false,
            errorText: '',
          },
        }))
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'name') {
            setForm(prevState => ({
              ...prevState,
              name: {
                ...prevState.name,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const onEmailChange = (name: string, value: string) => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    })
    schema
      .validate({ email: value })
      .then(val => {
        setForm(prevState => ({
          ...prevState,
          email: {
            ...prevState.email,
            value: val.email,
            error: false,
            errorText: '',
          },
        }))
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'email') {
            setForm(prevState => ({
              ...prevState,
              email: {
                ...prevState.email,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const onPasswordChange = (name: string, value: string) => {
    const schema = yup.object().shape({
      password: yup.string().min(7).required(),
    })
    schema
      .validate({ password: value })
      .then(val => {
        setForm(prevState => ({
          ...prevState,
          password: {
            ...prevState.password,
            value: val.password,
            error: false,
            errorText: '',
          },
        }))
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'password') {
            setForm(prevState => ({
              ...prevState,
              password: {
                ...prevState.password,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const onSubmit = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
      name: yup.string().min(2).required(),
      password: yup.string().min(8).required(),
    })
    schema
      .validate({
        email: form.email.value,
        name: form.name.value,
        password: form.password.value,
      })
      .then(val => {
        setLoading(true)

        let url = '/api/api/auth/signup'
        if (process.env.NODE_ENV === 'production') {
          url = '/api/auth/signup'
        }
        superagent
          .post(url)
          .send(val)
          .then(res => {
            const body = res.body as ResultApi
            if (body.result == 200) {
              setRegisterSuccess(true)
            } else {
              //alert('Submit failed');
            }
            setLoading(false)
          })
          .catch(err => {
            console.error(err)
          })
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'email') {
            setForm(prevState => ({
              ...prevState,
              email: {
                ...prevState.email,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'name') {
            setForm(prevState => ({
              ...prevState,
              name: {
                ...prevState.name,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'password') {
            setForm(prevState => ({
              ...prevState,
              password: {
                ...prevState.password,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const onShowHidePassword = () => {
    if (textShowHidePassword == 'SHOW') {
      setTextShowHidePassword('HIDE')
      setTypePassword('text')
    } else {
      setTextShowHidePassword('SHOW')
      setTypePassword('password')
    }
  }

  const render = () => {
    if (!registerSuccess) {
      return (
        <div
          className={classNames(
            'flex flex-row w-screen h-screen justify-center overflow-y-auto'
          )}
        >
          <div
            className={classNames(
              { hidden: !loading },
              'fixed z-20 flex flex-row w-screen h-screen justify-center items-center fixed inset-0 bg-black opacity-30'
            )}
          >
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>

          <div className={classNames('md:w-2/6 w-full mb-8')}>
            <div className="flex flex-col md:border pt-4 pl-4 pr-4 mt-8 pb-8">
              <div className="flex flex-col justify-center items-center w-full">
                <img
                  className="rounded-full w-16"
                  src={img_avatar}
                  alt="Logo Pinbeli"
                />
              </div>
              <div className="text-green-500 font-bold text-center text-base border-b mb-4 p-2">
                SIGN UP
              </div>
              <Input
                name="name"
                title="Name"
                type="text"
                value={form.name.value}
                error={form.name.error}
                errorText={form.name.errorText}
                onChange={onNameChange}
                placeholder="your name"
                disable={loading}
              ></Input>
              <Input
                name="email"
                title="email"
                type="email"
                value={form.email.value}
                error={form.email.error}
                errorText={form.email.errorText}
                onChange={onEmailChange}
                placeholder="your email"
                disable={disableEmailOtp}
                loader={emailLoading}
              ></Input>
              <Input
                name="password"
                title="Password"
                type={typePassword}
                value={form.password.value}
                error={form.password.error}
                errorText={form.password.errorText}
                onChange={onPasswordChange}
                placeholder="your password"
                disable={loading}
                textRight={textShowHidePassword}
                onClickTextRight={onShowHidePassword}
              ></Input>

              <div className="flex flex-row w-full justify-center mt-8">
                <Button
                  onClick={onSubmit}
                  disable={buttonDisable}
                  title="SIGN UP"
                  className="rounded-md w-1/2"
                />
              </div>
              <div className="mt-2">
                <Link to="/auth/login">
                  <div className="mt-4 text-gray-500 hover:text-blue-500 cursor-pointer text-sm">
                    Already have an account ?
                  </div>
                </Link>
              </div>
              <div className="mt-2 text-gray-500 hover:text-blue-500 cursor-pointer text-sm mb-2">
                Forgot password?
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex flex-row w-screen h-screen justify-center items-center overflow-y-auto">
          <div className="md:w-2/6 w-full mb-8">
            <div className="flex flex-col md:border pt-4 pl-4 pr-4 mt-8 pb-8">
              <div className="flex flex-col justify-center items-center w-full">
                <img
                  className="rounded-full w-16"
                  src={img_avatar}
                  alt="Logo Pinbeli"
                />
              </div>
              <div className="flex flex-col items-center font-bold text-gray-700 mt-6">
                Complete Registration
              </div>
              <div className="flex flex-col items-center text-gray-600 mt-4 text-sm text-center">
                You Have successfully Signed up. Thank You For Using This
                Application.
              </div>
              <div className="flex flex-col items-center text-gray-600 mt-4 text-sm text-center">
                You will be automatically redirected to your application. please
                wait
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return render()
}
