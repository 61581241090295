import React, { useEffect, useState } from 'react'
import { Table, Image, Tag, Popconfirm, Button, Divider } from 'antd'
import superagent from 'superagent'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  GlobalOutlined
} from '@ant-design/icons';
import {IMapPicker} from "./map-picker";


export function PengajuanBengkelBadanUsaha() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [namePicker, setNamePicker] = useState("");
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [posisiBengkel, setPosisiBengkel] = useState([]);

  useEffect(() => {
    loadData()
  }, [])

  const updateStatusData = (id, status) => {
    setLoading(true)
    let url = '/api/api/pengajuan/badanusaha/update'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/pengajuan/badanusaha/update'
    }
    superagent
      .post(url)
      .send({id, status})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          loadData();
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const onConfirm = (data) => {
    updateStatusData(data._id, 'verified');
  }
  
  const onCancel = (data) => {
    updateStatusData(data._id, 'new');
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telp',
      dataIndex: 'telp',
      key: 'telp',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Kota',
      dataIndex: '',
      key: 'kota',
      render: (data) => {
        return (
            <div>{data.alamat.kota}</div>
        )
      }
    },
    {
      title: 'Bengkel',
      dataIndex: '',
      key: 'bengkel',
      render: (data) => {
        return (
            <ul style={{listStyleType: 'circle'}}>
             {
                data.bengkel.map((bengkel, idx) => {
                    return (
                        <li key={idx}>{bengkel.name.toUpperCase()}</li>
                    )
                })
             }   
            </ul>
                
        )
      }
    },
    {
      title: 'Kode',
      dataIndex: 'kode_pengajuan',
      key: 'kode',
    },
    {
      title: 'Tanggal',
      dataIndex: '',
      key: 'created_at',
      render: (data) => {
        return (
          <div>{new Intl.DateTimeFormat(['id'], {
            dateStyle: 'full',
            timeStyle: 'short'
          }).format(new Date(data.created_at))}</div>
        )
      }
    },
    {
        title: 'status',
        dataIndex: '',
        key: 'status',
        render: (data) => {
          if(data.status == 'verified'){
            return (
              <Popconfirm
                title="Rubah Status"
                description="Rubah Status Bengkel, Verified atau New"
                onConfirm={() => onConfirm(data)}
                onCancel={() => onCancel(data)}
                okText="Verified"
                okType="dashed"
                cancelText="New"
              >
                <Button style={{color: 'green'}} icon={<CheckCircleOutlined />} type="dashed">Verified</Button>
              </Popconfirm>
            )
          } else {
            return (
              <Popconfirm
                title="Rubah Status"
                description="Rubah Status Bengkel, Verified atau New"
                onConfirm={() => onConfirm(data)}
                onCancel={() => onCancel(data)}
                okText="Verified"
                okType="dashed"
                cancelText="New"
              >
                <Button style={{color: 'orange'}} icon={<ClockCircleOutlined />} type="dashed">New</Button>
              </Popconfirm>
            )
          }
        }
      }
  ]

  const loadData = () => {
    setLoading(true)
    let url = '/api/api/webuser/pengajuan/badanusaha/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/webuser/pengajuan/badanusaha/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          for(let i=0;i<body.data.length;i++){
            body.data[i].key = body.data[i]._id;
          }
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onOk = (name, location) => {
    console.log(name);
    console.log(location);
  };

  return (
    <div className="flex flex-col p-6">
      <div className="text-xl border-b">Pengajuan Bengkel Badan Usaha</div>
      <Table 
        dataSource={data} 
        columns={columns} 
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, article) => {
            var keys = [];
            if (expanded) {
              keys.push(article._id);
            }

            setExpandedRowKeys(keys);
          },
          expandedRowRender: (record) => {
            let filenpwp = record['npwp'] as string;
            let isImagenpwp = false;
            if(filenpwp){
              const arr = filenpwp.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagenpwp = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagenpwp = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagenpwp = true;
                }
              }
            }
            let contentnpwp = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['npwp']}`}>View</a>;
            if(isImagenpwp){
              contentnpwp = <Image
                width={200}
                src={`https://ws.nagara.co.id/api/files/download/${record['npwp']}`}
              />
            }
            //-----
            let filektppengurus = record['ktpfile_pengurus'] as string;
            let isImagektppengurus = false;
            if(filektppengurus){
              const arr = filektppengurus.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagektppengurus = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagektppengurus = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagektppengurus = true;
                }
              }
            }
            let contentktppengurus = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['ktpfile_pengurus']}`}>View</a>;
            if(isImagektppengurus){
              contentktppengurus = <Image
                width={200}
                src={`https://ws.nagara.co.id/api/files/download/${record['ktpfile_pengurus']}`}
              />
            }
             //-----
             let filenpwppengurus = record['npwpfile_pengurus'] as string;
            let isImagenpwppengurus = false;
            if(filenpwppengurus){
              const arr = filenpwppengurus.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagenpwppengurus = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagenpwppengurus = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagenpwppengurus = true;
                }
              }
            }
            let contentnpwppengurus = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['npwpfile_pengurus']}`}>View</a>;
            if(isImagenpwppengurus){
              contentnpwppengurus = <Image
                width={200}
                src={`https://ws.nagara.co.id/api/files/download/${record['npwpfile_pengurus']}`}
              />
            }

            return (
              <div style={{ marginLeft: 50 }}>
                <div style={{borderBottom: '1px solid gray', fontWeight: 500}}>List Bengkel</div>
                
                    {
                      record.bengkel.map((bengkel, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <table>
                          <tbody>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['name'].toUpperCase()}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['telp']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama Penanggung Jawab</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['nama_penanggung_jawab']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp Penanggung Jawab</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['telp_penanggung_jawab']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kota</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kota']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kecamatan</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kecamatan']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kelurahan</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kelurahan']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['alamat']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kodepos</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kodepos']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Posisi</td>
                            <td style={{borderBottom: '1px solid gray'}}>
                            <Button onClick={() => {
                              setNamePicker(bengkel['name'].toUpperCase())
                              setIsPickerOpen(true);
                              setPosisiBengkel([parseFloat(bengkel['latitude']), parseFloat(bengkel['longitude'])])
                            }} style={{color: 'green'}} icon={<GlobalOutlined />} type="dashed">Show Map</Button>
                              {bengkel['latitude']} {bengkel['longitude']}</td>
                          </tr>
                          </tbody>
                          </table>
                          <Divider />
                          </React.Fragment>
                        )
                    })
                    }
                  
                <Divider />
                <table>
                  <tbody>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama Badan Usaha</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['name']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>NIB</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['nib']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>NPWP</td>
                      <td style={{borderBottom: '1px solid gray'}}>{contentnpwp}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['telp']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Fax</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['fax']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Email</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['email']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kota</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat']['kota']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kecamatan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat']['kecamatan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kelurahan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat']['kelurahan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>alamat</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat']['alamat']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kodepos</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat']['kodepos']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Pengurus</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>name</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['pengurus']['name']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>nik</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['pengurus']['nik']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>KTP</td>
                              <td style={{borderBottom: '1px solid gray'}}>{contentktppengurus}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>NPWP</td>
                              <td style={{borderBottom: '1px solid gray'}}>{contentnpwppengurus}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>jabatan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['pengurus']['jabatan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>telp</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['pengurus']['telp']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>email</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['pengurus']['email']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat Pengurus Sesuai KTP</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kota</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_ktp']['kota']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kecamatan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_ktp']['kecamatan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kelurahan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_ktp']['kelurahan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>alamat</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_ktp']['alamat']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kodepos</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_ktp']['kodepos']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat Pengurus Domisili</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kota</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_domisili']['kota']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kecamatan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_domisili']['kecamatan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kelurahan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_domisili']['kelurahan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>alamat</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_domisili']['alamat']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kodepos</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['alamat_pengurus_domisili']['kodepos']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
        }}
      />
      <IMapPicker
        isModalOpen={isPickerOpen}
        setIsModalOpen={setIsPickerOpen}
        name={namePicker}
        posisi={posisiBengkel}
        onOk={onOk}
      />
    </div>
  )
}
