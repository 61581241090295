import React, { useState, useEffect } from 'react'
import superagent from 'superagent'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import produce from 'immer'
import noimg from '../../../assets/images/noimage.jpeg'

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'

export function ProductCategory() {
  const [data, setData] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [idDelete, setIdDelete] = useState<string>()
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    let url = '/api/api/product/category/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/product/category/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const deleteData = () => {
    let url = '/api/api/product/category/delete'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/product/category/delete'
    }
    superagent
      .post(url)
      .send({
        filter: {
          id: idDelete,
        },
      })
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          const nextState = produce(data, draftState => {
            let idx = draftState.findIndex(d => d._id == idDelete)
            if (idx > -1) {
              draftState.splice(idx, 1)
            }
          })
          setData(nextState)
        } else {
          //alert('Submit failed');
        }
        handleOpenDialogDelete()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleOpenDialogDelete = () => setOpenDialogDelete(!openDialogDelete)

  const onPilihDelete = (id: string) => {
    setIdDelete(id)
    handleOpenDialogDelete()
  }

  const onDelete = () => {
    deleteData()
  }

  return (
    <div className="m-4 p-4 bg-white">
      <div className="flex flex-row justify-between text-lg border-b mb-4">
        <div>Listing Category Product</div>
        <div className="mb-1">
          <Button onClick={() => navigate('/product/category/insert/new')}>
            + New Category Product
          </Button>
        </div>
      </div>
      <div className="grid md:grid-cols-4 hover:auto-cols-min gap-4">
        {data.map((dt, idx) => {
          let myTruncatedString = dt.description.substring(0, 40)
          let imgpath = noimg
          let imgname = 'No Image'
          if (dt.images) {
            if (dt.images.length > 0) {
              imgpath =
                'https://img.nagara.co.id/nagara/' + dt.images[0].filename
              imgname = dt.images[0].name
            }
          }
          return (
            <div
              key={idx}
              className="max-w-sm rounded overflow-hidden shadow-lg"
            >
              <img className="w-full" src={imgpath} alt={imgname} />
              <div className="px-3 py-3">
                <div className="font-bold mb-2">{dt.name}</div>
                <p className="text-gray-700 text-sm">{myTruncatedString}</p>
              </div>
              <div className="flex flex-row justify-between border-t">
                <span className=""></span>
                <span className="flex flex-row text-xs">
                  <div
                    onClick={() =>
                      navigate('/product/category/insert/' + dt._id)
                    }
                    className="p-1 hover:text-blue-400 cursor-pointer"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => onPilihDelete(dt._id)}
                    className="p-1 hover:text-blue-400 cursor-pointer"
                  >
                    Delete
                  </div>
                </span>
              </div>
            </div>
          )
        })}
      </div>

      <Dialog
        open={openDialogDelete}
        handler={handleOpenDialogDelete}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader>Warning.</DialogHeader>
        <DialogBody divider>Kamu yakin mau hapus ?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenDialogDelete}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="red" onClick={onDelete}>
            <span>Delete</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )
}
