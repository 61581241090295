import React, { useState, useEffect, ReactChild } from 'react'
import classNames from 'classnames'

export interface CheckBoxProps {
  children?: ReactChild
  check: boolean
  error?: boolean
  onChange?: (value: boolean) => void
}

export function CheckBox(props: CheckBoxProps) {
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (props.check != undefined) {
      setCheck(props.check)
    }
  }, [props.check])

  useEffect(() => {
    if (props.error != undefined) {
      setError(props.error)
    }
  }, [props.error])

  const onChange = () => {
    const c = !check
    setCheck(c)
    if (props.onChange) {
      props.onChange(c)
    }
  }

  return (
    <div className="flex flex-row pt-2">
      <div className="flex items-center">
        <input
          onChange={() => onChange()}
          checked={check}
          type="checkbox"
          className="w-4 h-4 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300"
        />
      </div>
      <div
        className={classNames(
          { 'text-red-600': error },
          { 'text-gray-600': !error },
          'ml-3 text-sm'
        )}
      >
        {props.children}
      </div>
    </div>
  )
}
