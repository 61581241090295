import React, { useState, useEffect } from 'react'
import superagent from 'superagent'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { AddCert } from './add-cert'
import { ViewCert } from './cert-view'

interface ICert {
  name: string
  startDate: string
}
export function Bengkel() {
  const [data, setData] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [bengkelId, setBengkelId] = useState<string>()
  const [certId, setCertId] = useState<string>()
  const [wsId, setWsId] = useState<string>()
  const [bengkelName, setBengkelName] = useState<string>()
  const [showDialog, setShowDialog] = useState(false)
  const [showViewer, setShowViewer] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    let url = '/api/api/bengkel/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/bengkel/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onDetail = id => {
    navigate('/bengkel-detail/' + id)
  }

  const onAddCert = (id, name) => {
    setBengkelId(id)
    setBengkelName(name)
    setShowDialog(!showDialog)
  }

  const onViewCert = (cert, dt) => {
    console.log(cert)
    //setBengkelId(id);
    //setBengkelName(name);
    setCertId(cert.cert.value)
    setWsId(dt._id)
    setShowViewer(!showViewer)
  }

  const onDialogClose = tp => {
    setShowDialog(!showDialog)
    console.log(tp)
  }

  return (
    <div className="m-4 p-4 bg-white">
      <div className="text-lg border-b mb-4">Listing Bengkel</div>
      <div>
        {data.map((dt, idx) => {
          let icert = []
          if (dt.cert) {
            icert = dt.cert
          }
          return (
            <div key={idx} className="flex flex-col hover:bg-gray-100">
              <div className="flex flex-row justify-between border-b mb-4 p-2">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center mb-1">
                    <div className="mr-4 font-semibold">{dt.name}</div>
                    <div
                      className={classNames(
                        {
                          'text-green-700': dt.status == 'ACCEPT',
                          'text-yellow-800': dt.status == 'WAITING',
                          'text-red-800': dt.status == 'DENIED',
                        },
                        'font-semibold text-sm mr-4'
                      )}
                    >
                      ( {dt.status} )
                    </div>
                    <div
                      onClick={() => onAddCert(dt._id, dt.name)}
                      className="text-sm cursor-pointer hover:text-blue-400 mr-2"
                    >
                      Add Certificate
                    </div>
                  </div>
                  <div className="text-sm">{dt.address}</div>
                </div>
                <div className="flex flex-row">
                  <div
                    onClick={() => onDetail(dt._id)}
                    className="w-8 text-sm cursor-pointer hover:text-blue-400"
                  >
                    Detail
                  </div>
                </div>
              </div>

              <div
                className={classNames(
                  { hidden: !dt.cert },
                  'flex flex-col border-b -mt-2 mb-4 pb-4'
                )}
              >
                <div className="text-xs text-gray-600">certificate</div>
                {icert.map((ct: ICert, idxx) => {
                  return (
                    <div
                      className="flex flex-row text-sm text-gray-800"
                      key={idxx}
                    >
                      <div className="mr-2">{ct.name} </div>
                      <div className="mr-2">
                        ({' '}
                        {new Intl.DateTimeFormat('id').format(
                          new Date(ct.startDate)
                        )}{' '}
                        )
                      </div>
                      <div
                        onClick={() => onViewCert(ct, dt)}
                        className="mr-2 cursor-pointer hover:text-blue-400"
                      >
                        view{' '}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <AddCert
        onClose={onDialogClose}
        bengkelName={bengkelName}
        bengkelId={bengkelId}
        show={showDialog}
      />
      <ViewCert show={showViewer} wsId={wsId} certId={certId} />
    </div>
  )
}
