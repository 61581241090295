import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react'
import superagent from 'superagent'
import classNames from 'classnames'
import Select from 'react-select'
import Datepicker from 'react-tailwindcss-datepicker'
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types'
import * as yup from 'yup'
import { ResultApi, ParamsData } from '../../helper'
import { PDFObject } from 'react-pdfobject'

export function ViewCert(props) {
  const [openDialogAddCert, setOpenDialogAddCert] = useState(false)
  const [certId, setCertId] = useState()
  const [wsId, setWsId] = useState()

  useEffect(() => {
    if (props.show != undefined) {
      if (props.show) {
        //loadDataCert();
      }
      setOpenDialogAddCert(props.show)
    }
  }, [props.show])

  useEffect(() => {
    if (props.certId != undefined) {
      setCertId(props.certId)
    }
  }, [props.certId])

  useEffect(() => {
    if (props.wsId != undefined) {
      setWsId(props.wsId)
    }
  }, [props.wsId])

  const handleOpenDialogDelete = tp => {
    if (!tp) {
      tp = 'cancel'
    }
    let a = !openDialogAddCert
    if (!a) {
      if (props.onClose != undefined) {
        props.onClose(tp)
      }
    }
    setOpenDialogAddCert(a)
  }

  return (
    <Dialog
      open={openDialogAddCert}
      handler={handleOpenDialogDelete}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>View Cert</DialogHeader>
      <DialogBody divider>
        <div className="flex flex-col w-full p-4">
          <div className="w-full">
            <PDFObject
              height="400px"
              pdfOpenParams={{
                page: 1,
                pagemode: 'none',
              }}
              url={
                process.env.NODE_ENV === 'production'
                  ? '/api/pdf/download/' + certId + '_' + wsId
                  : '/api/api/pdf/download/' + certId + '_' + wsId
              }
            />
          </div>
        </div>
      </DialogBody>
    </Dialog>
  )
}
