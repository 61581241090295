import React, { useState, useEffect } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import classNames from 'classnames'
import {
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid'
import produce from 'immer'
import { useNavigate } from 'react-router-dom'
import { IconLib } from './iconlib'

interface IMenu {
  name: string
  title: string
  selected: boolean
  icon: string
  link?: string
  child?: any
}

export function SideBarMenu(props) {
  const navigate = useNavigate()
  const [small, setSmall] = useState(false)
  const [menu, setMenu] = useState<Array<IMenu>>([])

  useEffect(() => {
    if (props.menu) {
      const nextState = produce(menu, draftState => {
        if (draftState.length == 0) {
          for (let i = 0; i < props.menu.length; i++) {
            let t = JSON.parse(JSON.stringify(props.menu[i]))
            t.selected = false
            const child = t.child
            if (child) {
              for (let x = 0; x < child.length; x++) {
                child[x].selected = false
              }
            }
            draftState.push(t)
          }
        } else {
          for (let i = 0; i < draftState.length; i++) {
            draftState[i].selected = false
            const child = draftState[i].child
            if (child) {
              for (let x = 0; x < child.length; x++) {
                child[x].selected = false
              }
            }
          }
        }

        for (let i = 0; i < draftState.length; i++) {
          let ada = false
          if (draftState[i].child) {
            const child = draftState[i].child
            if (child) {
              for (let x = 0; x < child.length; x++) {
                if (child[x].link == location.pathname) {
                  child[x].selected = true
                  ada = true
                  break
                }
              }
            }
          }
          if (ada) {
            break
          }
        }
        //props.menu.map(m => {
        //  draftState.push(m);
        //})
        //draftState = props.menu;
      })
      //console.log('nextState ', nextState)
      //console.log('set menu baru ', nextState.length)
      setMenu(nextState)
      //setMenu(props.menu)
    }
  }, [props.menu])

  const menuOnClick = (parent, name) => {
    const nextState = produce(menu, draftState => {
      draftState.map(mn => {
        if (mn.child) {
          mn.child.map(mnc => {
            mnc.selected = false
          })
        }
      })
      const df = draftState.find(mn => mn.name == parent)
      if (df && df.child) {
        const dd = df.child.find(mn => mn.name == name)
        if (dd) {
          dd.selected = true
        }
      }
    })
    setMenu(nextState)
  }

  useEffect(() => {
    if (props.isSmall != undefined) {
      setSmall(props.isSmall)
    }
  }, [props.isSmall])

  const onChangeSmallMenuToNormal = () => {
    setSmall(false)
    if (props.onSetToNormal) {
      props.onSetToNormal()
    }
  }

  const render = () => {
    //console.log('render sini ', menu.length)
    return (
      <div
        className={classNames(
          { 'w-80': !small },
          { 'w-14': small },
          'h-full overflow-auto transition-width ease-in-out scroll-smooth shadow-lg pt-4 z-20'
        )}
      >
        {menu.map((mn, idx) => {
          let haschild = false
          if (mn.child) {
            if (mn.child.length > 0) {
              haschild = true
            }
          }
          if (haschild) {
            return (
              <Disclosure as="div" className="" key={idx}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames('w-full pt-2 pb-2')}
                    >
                      <div className="flex flex-row justify-between ml-4">
                        <div className="grow flex flex-row -mb-2">
                          <div
                            onClick={e => {
                              onChangeSmallMenuToNormal()
                              e.stopPropagation()
                            }}
                            className={classNames(
                              { 'hover:text-blue-700': small },
                              'flex flex-row items-center w-6 p-1 text-gray-400'
                            )}
                          >
                            <IconLib name={mn.icon} />
                          </div>
                          <div
                            className={classNames(
                              { hidden: small },
                              'flex flex-row items-center ml-2 text-gray-600 text-sm'
                            )}
                          >
                            {mn.title}
                          </div>
                        </div>
                        <div
                          className={classNames(
                            { hidden: small },
                            'w-4 flex-none mr-2 flex flex-row items-center text-gray-400'
                          )}
                        >
                          <ChevronRightIcon
                            className={classNames({
                              'transform rotate-90': open,
                            })}
                          />
                        </div>
                      </div>
                    </Disclosure.Button>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel
                        className={classNames(
                          { hidden: small },
                          'flex flex-col text-gray-500 mt-2 mb-2'
                        )}
                      >
                        {mn.child?.map((mnc, idx) => {
                          return (
                            <div
                              key={idx}
                              onClick={() => menuOnClick(mn.name, mnc.name)}
                              className={classNames({
                                'bg-green-50': mnc.selected,
                              })}
                            >
                              <div
                                onClick={e => {
                                  //e.stopPropagation();
                                  if (!mnc.child && mnc.link) {
                                    navigate(mnc.link)
                                  }
                                }}
                                className={classNames(
                                  'flex flex-row hover:text-green-500 cursor-pointer p-1 text-sm ml-8'
                                )}
                              >
                                <ChevronDoubleRightIcon className="w-3" />
                                <div className="ml-2">{mnc.title}</div>
                              </div>
                            </div>
                          )
                        })}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )
          } else {
            //console.log('sisi')
            return (
              <div
                key={idx}
                className="pt-2 pb-2 flex flex-row justify-between ml-4 cursor-pointer"
              >
                <div className="grow flex flex-row">
                  <div
                    onClick={e => {
                      onChangeSmallMenuToNormal()
                      e.stopPropagation()
                      if (!mn.child && mn.link) {
                        location.href = mn.link
                      }
                    }}
                    className={classNames(
                      { 'hover:text-blue-700': small },
                      'flex flex-row items-center w-6 p-1 text-gray-400'
                    )}
                  >
                    <div>{mn.icon}</div>
                  </div>
                  <div
                    onClick={e => {
                      e.stopPropagation()
                      if (!mn.child && mn.link) {
                        location.href = mn.link
                      }
                    }}
                    className={classNames(
                      { hidden: small },
                      'flex flex-row items-center ml-2 text-gray-600 text-sm'
                    )}
                  >
                    {mn.title}
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  return render()
}
