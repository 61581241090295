import React, { useState, useEffect } from 'react'
import superagent from 'superagent'
import { Routes, Route, useParams } from 'react-router-dom'
import { Input, DialogModal } from '../../components'
import { Button } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

export function BengkelDetail() {
  let { id: bengkelId } = useParams()
  const navigate = useNavigate()
  const [showModalAccept, setShowModalAccept] = useState(false)
  const [showModalViewer, setShowModalViewer] = useState(false)
  const [showModalDenied, setShowModalDenied] = useState(false)
  const [filedoc, setFiledoc] = useState()
  const [data, setData] = useState({
    name: '',
    address: '',
    geolocation: {
      position: [0, 0],
    },
    user: {
      email: '',
    },
    phone: '',
    email: '',
    year: '',
    luas: '',
    konsentrasi: '',
    pemilik: '',
    pengurus: '',
    peralatanKhusus: '',
    peralatanTangan: '',
    ujiPerlindungan: '',
    ujiHambatan: '',
    keselamatan: '',
    mekanikInJumlah: '',
    mekanikInNama: '',
    mekanikInTugas: '',
    mekanikPeJumlah: '',
    mekanikPeNama: '',
    mekanikPeTugas: '',
    mekanikLaJumlah: '',
    mekanikLaNama: '',
    mekanikLaTugas: '',
    toolMinimum: false,
    Resistant: false,
    Insulation: false,
    CurrentTester: false,
    OthersMeters: false,
    Oscilloscope: false,
    APAR: false,
    PeralatanUji: false,
    SoundMeter: false,
    Timbangan: false,
    AntiKejutListrik: false,
    Laptop: false,
    files: [],
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    //loadData();
  }, [])

  const buttons = [
    {
      name: 'btnok',
      title: 'Accept',
    },
    {
      name: 'btncancel',
      title: 'Cancel',
    },
  ]

  const buttonsD = [
    {
      name: 'btndenied',
      title: 'Denied',
    },
    {
      name: 'btncancel',
      title: 'Cancel',
    },
  ]

  const buttonsE = [
    {
      name: 'btnok',
      title: 'OK',
    },
  ]

  useEffect(() => {
    loadData()
  }, [bengkelId])

  const loadData = () => {
    let url = '/api/api/bengkel/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/bengkel/retrieve'
    }
    superagent
      .post(url)
      .send({
        filter: {
          id: bengkelId,
        },
      })
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          //setData(body.data[0])
          let bd = body.data[0]
          setData(prevState => ({
            ...prevState,
            name: bd.name,
            address: bd.address,
            geolocation: bd.geolocation,
            user: bd.user,
            phone: bd.phone,
            email: bd.email,
            year: bd.year,
            luas: bd.luas,
            konsentrasi: bd.konsentrasi,
            pemilik: bd.pemilik,
            pengurus: bd.pengurus,
            peralatanKhusus: bd.peralatanKhusus,
            peralatanTangan: bd.peralatanTangan,
            ujiPerlindungan: bd.ujiPerlindungan,
            ujiHambatan: bd.ujiHambatan,
            keselamatan: bd.keselamatan,
            mekanikInJumlah: bd.mekanikInJumlah,
            mekanikInNama: bd.mekanikInNama,
            mekanikInTugas: bd.mekanikInTugas,
            mekanikPeJumlah: bd.mekanikPeJumlah,
            mekanikPeNama: bd.mekanikPeNama,
            mekanikPeTugas: bd.mekanikPeTugas,
            mekanikLaJumlah: bd.mekanikLaJumlah,
            mekanikLaNama: bd.mekanikLaNama,
            mekanikLaTugas: bd.mekanikLaTugas,
            toolMinimum: bd.toolMinimum,
            Resistant: bd.Resistant,
            Insulation: bd.Insulation,
            CurrentTester: bd.CurrentTester,
            OthersMeters: bd.OthersMeters,
            Oscilloscope: bd.Oscilloscope,
            APAR: bd.APAR,
            PeralatanUji: bd.PeralatanUji,
            SoundMeter: bd.SoundMeter,
            Timbangan: bd.Timbangan,
            AntiKejutListrik: bd.AntiKejutListrik,
            Laptop: bd.Laptop,
            files: bd.files,
          }))
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onAccept = () => {
    setShowModalAccept(true)
  }

  const onDenied = () => {
    setShowModalDenied(true)
  }

  const onCancel = () => {
    navigate('/bengkel')
  }

  const onDialogModalViewerClose = name => {
    setFiledoc(undefined)
    setShowModalViewer(false)
  }

  const onDialogModalDeniedClose = name => {
    if (name == 'btndenied') {
      let url = '/api/api/bengkel/proses'
      if (process.env.NODE_ENV === 'production') {
        url = '/api/bengkel/proses'
      }
      superagent
        .post(url)
        .send({
          id: bengkelId,
          status: 'DENIED',
        })
        .then(res => {
          const body = res.body
          if (body.result == 200) {
            //setData(body.data[0])
            navigate('/bengkel')
          } else {
            //alert('Submit failed');
          }
          setLoading(false)
        })
        .catch(err => {
          console.error(err)
        })
    }
    setShowModalDenied(false)
  }

  const onDialogModalAcceptClose = name => {
    if (name == 'btnok') {
      let url = '/api/api/bengkel/proses'
      if (process.env.NODE_ENV === 'production') {
        url = '/api/bengkel/proses'
      }
      superagent
        .post(url)
        .send({
          id: bengkelId,
          status: 'ACCEPT',
        })
        .then(res => {
          const body = res.body
          if (body.result == 200) {
            //setData(body.data[0])
            navigate('/bengkel')
          } else {
            //alert('Submit failed');
          }
          setLoading(false)
        })
        .catch(err => {
          console.error(err)
        })
    }
    setShowModalAccept(false)
  }

  useEffect(() => {
    if (filedoc) {
      console.log(filedoc)
      setShowModalViewer(true)
    }
  }, [filedoc])

  const onOpenFile = file => {
    setFiledoc('https://img.nagara.co.id/nagara/' + file.filename)
  }

  const getMap = geolocation => {
    if (geolocation.position) {
      return (
        <a
          className="hover:text-blue-500"
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${geolocation.position[0]},${data.geolocation.position[1]}`}
        >
          Link To Map
        </a>
      )
    } else {
      return <div className="text-red-500">Data Map Tidak Ada</div>
    }
  }

  return (
    <div className="m-4 p-4 bg-white">
      <div className="flex flex-row justify-between border-b mb-4">
        <div className="text-lg font-bold">Detail Bengkel</div>
        <div className="flex flex-row">
          <Button onClick={onCancel} className="mr-4" variant="outlined">
            Cancel
          </Button>
          <Button color="red" onClick={onDenied} className="mr-4">
            Denied
          </Button>

          <Button onClick={onAccept}>Accept</Button>
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Name</div>
          <div>{data.name}</div>
        </div>
        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Alamat</div>
          <div>{data.address}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Map</div>
          <div>{getMap(data.geolocation)}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Phone</div>
          <div>{data.phone}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Email</div>
          <div>{data.user.email}</div>
        </div>

        {/*<div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Tahun Pendirian</div>
          <div>{data.year}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Luas Area Bengkel</div>
          <div>{data.luas}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Konsentrasi Bengkel</div>
          <div>{data.konsentrasi}</div>
        </div>*/}

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Nama Kepala Bengkel</div>
          <div>{data.pemilik}</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b mt-4">
          {' '}
          Data Peralatan Wajib
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.toolMinimum ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">
            Tools Minimum Bengkel termasuk Soldering Tools
          </div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.Resistant ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Resistant</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.Insulation ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Insulation</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.CurrentTester ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Current Tester</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.OthersMeters ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Others Meters</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.Oscilloscope ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Oscilloscope</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.APAR ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">APAR</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b mt-4">
          {' '}
          Data Peralatan Lanjutan
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.PeralatanUji ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Peralatan Uji (Lampu, Sentuh, Dyno)</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.SoundMeter ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Sound Meter</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.Timbangan ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Timbangan</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.AntiKejutListrik ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Anti Kejut Listrik</div>
        </div>

        <div className="flex flex-row mb-4 border-b">
          <div className="mr-2">
            {data.Laptop ? (
              <Icon path={mdiCheckCircle} size={0.7} color={'green'} />
            ) : (
              <Icon path={mdiCloseCircle} size={0.7} color={'red'} />
            )}
          </div>
          <div className="text-sm">Komputer atau Laptop</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b mt-4">
          {' '}
          File Upload
        </div>

        <div className="flex flex-col mb-4">
          <table className="p-4 text-sm">
            <tbody>
              {data.files.map((file, idx) => {
                return (
                  <tr key={idx} className="border-b">
                    <td>
                      <a
                        href={
                          'https://img.nagara.co.id/nagara/' + file.filename
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file.name}
                      </a>
                      {/*<div onClick={() => {
                      //onOpenFile(file)
                    }} className="hover:text-blue-600 cursor-pointer">{file.name}</div>
                  */}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {/*<div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Susunan Pengurus Bengkel</div>
          <div>{data.pengurus}</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b mt-4">
          {' '}
          Daftar Peralatan
        </div>
        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">
            Daftar Peralatan Khusus Instalasi Penggerak Motor
          </div>
          <div>{data.peralatanKhusus}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">
            Daftar Peralatan Tangan & Peralatan Bertenaga
          </div>
          <div>{data.peralatanTangan}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">
            Daftar Peralatan Uji Perlindungan Sentuh Listrik
          </div>
          <div>{data.ujiPerlindungan}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Daftar Peralatan Uji Hambatan Solusi</div>
          <div>{data.ujiHambatan}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Daftar Peralatan Keselamatan</div>
          <div>{data.keselamatan}</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b-2 mt-4">
          Mekanik Instalatur
        </div>
        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Jumlah Mekanik Instalatur</div>
          <div>{data.mekanikInJumlah}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Nama Mekanik Instalatur</div>
          <div>{data.mekanikInNama}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Tugas Mekanik Instalatur</div>
          <div>{data.mekanikInTugas}</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b-2 mt-4">
          Mekanik Perawatan
        </div>
        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Jumlah Mekanik Perawatan</div>
          <div>{data.mekanikPeJumlah}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Nama Mekanik Perawatan</div>
          <div>{data.mekanikPeNama}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Tugas Mekanik Perawatan</div>
          <div>{data.mekanikPeTugas}</div>
        </div>

        <div className="flex flex-col mb-4 font-bold border-b-2 mt-4">
          Mekanik Lainnya
        </div>
        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Jumlah Mekanik Lainnya</div>
          <div>{data.mekanikLaJumlah}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Nama Mekanik Lainnya</div>
          <div>{data.mekanikLaNama}</div>
        </div>

        <div className="flex flex-col mb-4 border-b">
          <div className="text-xs">Tugas Mekanik Lainnya</div>
          <div>{data.mekanikLaTugas}</div>
      </div>*/}
      </div>

      <DialogModal
        title="Confirm"
        buttons={buttons}
        open={showModalAccept}
        onClose={onDialogModalAcceptClose}
      >
        <p className="text-sm text-gray-500">
          Are you sure want to accept this application?
        </p>
      </DialogModal>

      <DialogModal
        title="Confirm"
        buttons={buttonsD}
        open={showModalDenied}
        onClose={onDialogModalDeniedClose}
      >
        <p className="text-sm text-gray-500">
          Are you sure want to DENIED this application?
        </p>
      </DialogModal>

      <DialogModal
        title="Viewer"
        buttons={buttonsE}
        open={showModalViewer}
        onClose={onDialogModalViewerClose}
      >
        <DocViewer documents={[filedoc]} pluginRenderers={DocViewerRenderers} />
      </DialogModal>
    </div>
  )
}
