import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import { Button, Modal } from "antd";
import Icon from '@mdi/react'
import {
  mdiMapMarker 
} from '@mdi/js'

const AnyReactComponent = ({ text }) => <Icon path={mdiMapMarker} size={1.7} color={'#FF004D'} />;

export const IMapPicker = ({ posisi, isModalOpen, setIsModalOpen, name, onOk }) => {
  const [center, setCenter] = useState({
    lat: posisi[0],
    lng: posisi[1]
  })
  const [key, setKey] = useState(1)
  
  useEffect(() => {
    console.log('berubah ', posisi)
    setCenter({
      lat: posisi[0],
      lng: posisi[1]
    })
    setKey(key+1);
  }, [posisi])

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={name}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div style={{ height: '70vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCv7N6y4mLsuyiHAHz3Hw9j-JiDhDhYtHo" }}
          center={center}
          key={key}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={center.lat}
            lng={center.lng}
            text={name}
          />
        </GoogleMapReact>
      </div>
    </Modal>
  );
};
