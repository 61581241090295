import React,{useState, useEffect} from 'react'
import { Card, Col, Row, Statistic } from 'antd';
import superagent from 'superagent'

export function Dashboard() {
  const [loading, setLoading] = useState(false)
  const [bengkel_perorangan, setBengkel_perorangan] = useState(0);
  const [bengkel_badanusaha, setBengkel_badanusaha] = useState(0);
  const [konversi, setKonversi] = useState(0);
  const [konversi_verified, setKonversi_verified] = useState(0);
  const [konversi_selesai, setKonversi_selesai] = useState(0);
  const [terverifikasi, setTerverifikasi] = useState(0);
  //const [proses_konversi, setProses_konversi] = useState(0);
  //const [selesai_konversi, setSelesai_konversi] = useState(0);
  const [proses_SRUT, setProses_SRUT] = useState(0);
  const [proses_Surat_NKBB, setProses_Surat_NKBB] = useState(0);

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(true)
    let url = '/api/api/dashboard/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/dashboard/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          setBengkel_perorangan(body.data.bengkel_perorangan)
          setBengkel_badanusaha(body.data.bengkel_badanusaha)
          setKonversi(body.data.konversi)
          setKonversi_verified(body.data.konversi_verified)
          setKonversi_selesai(body.data.konversi_selesai)
          setTerverifikasi(body.data.terverifikasi)
          //setProses_konversi(body.data.proses_konversi)
          //setSelesai_konversi(body.data.selesai_konversi)
          setProses_SRUT(body.data.proses_SRUT)
          setProses_Surat_NKBB(body.data.proses_Surat_NKBB)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  return (
    <div className="flex flex-col p-8">
      <div className="text-xl border-b mb-4">Dashboard</div>
      <div className="mb-2">Pengajuan Bengkel</div>
      <Row className="mb-4" gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Pengajuan Bengkel Badan Usaha"
              value={bengkel_badanusaha}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Pengajuan Bengkel Perorangan"
              value={bengkel_perorangan}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="jumlah terverifikasi"
              value={terverifikasi}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

      </Row>

      <div className="mb-2">Pengajuan Konversi</div>

      <Row className="mb-4" gutter={[16, 16]}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Pengajuan Konversi"
              value={konversi}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

        
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="jumlah Terverifikasi"
              value={konversi_verified}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="jumlah selesai konversi"
              value={konversi_selesai}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="jumlah proses SRUT"
              value={proses_SRUT}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="jumlah proses Surat NKBB"
              value={proses_Surat_NKBB}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>

      </Row>


    </div>
  )
}
