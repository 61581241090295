import React, { useEffect, useState } from 'react'
import { Table, Image, Tag, Button, Popconfirm, Divider  } from 'antd'
import superagent from 'superagent'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  GlobalOutlined
} from '@ant-design/icons';
import {IMapPicker} from "./map-picker";

export function PengajuanBengkelPerorangan() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [namePicker, setNamePicker] = useState("");
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [posisiBengkel, setPosisiBengkel] = useState([]);

  useEffect(() => {
    console.log('asdadsad')
    loadData()
  }, [])

  const updateStatusData = (id, status) => {
    setLoading(true)
    let url = '/api/api/pengajuan/perorangan/update'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/pengajuan/perorangan/update'
    }
    superagent
      .post(url)
      .send({id, status})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          loadData();
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const onConfirm = (data) => {
    updateStatusData(data._id, 'verified');
  }
  
  const onCancel = (data) => {
    updateStatusData(data._id, 'new');
  }
  
  const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Telp',
        dataIndex: 'telp',
        key: 'telp',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Kota',
        dataIndex: '',
        key: 'kota',
        render: (data) => {
          return (
              <div>{data.domisili.kota}</div>
          )
        }
      },
      {
        title: 'Bengkel',
        dataIndex: '',
        key: 'bengkel',
        render: (data) => {
          return (
              <ul style={{listStyleType: 'circle'}}>
               {
                  data.bengkel.map((bengkel, idx) => {
                      return (
                          <li key={idx}>{bengkel.name.toUpperCase()}</li>
                      )
                  })
               }   
              </ul>
                  
          )
        }
      },
      {
        title: 'Kode',
        dataIndex: 'kode_pengajuan',
        key: 'kode',
      },
      {
        title: 'Tanggal',
        dataIndex: '',
        key: 'created_at',
        render: (data) => {
          return (
            <div>{new Intl.DateTimeFormat(['id'], {
              dateStyle: 'full',
              timeStyle: 'short'
            }).format(new Date(data.created_at))}</div>
          )
        }
      },
      {
        title: 'status',
        dataIndex: '',
        key: 'status',
        render: (data) => {
          if(data.status == 'verified'){
            return (
              <Popconfirm
                title="Rubah Status"
                description="Rubah Status Bengkel, Verified atau New"
                onConfirm={() => onConfirm(data)}
                onCancel={() => onCancel(data)}
                okText="Verified"
                okType="dashed"
                cancelText="New"
              >
                <Button style={{color: 'green'}} icon={<CheckCircleOutlined />} type="dashed">Verified</Button>
              </Popconfirm>
            )
          } else {
            return (
              <Popconfirm
                title="Rubah Status"
                description="Rubah Status Bengkel, Verified atau New"
                onConfirm={() => onConfirm(data)}
                onCancel={() => onCancel(data)}
                okText="Verified"
                okType="dashed"
                cancelText="New"
              >
                <Button style={{color: 'orange'}} icon={<ClockCircleOutlined />} type="dashed">New</Button>
              </Popconfirm>
            )
          }
        }
      }
    ]

  const loadData = () => {
    setLoading(true)
    let url = '/api/api/webuser/pengajuan/perorangan/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/webuser/pengajuan/perorangan/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        console.log('masuk sini .data ')
        if (body.result == 200) {
          for(let i=0;i<body.data.length;i++){
            body.data[i].key = body.data[i]._id;
          }
          console.log('body.data ', body.data)
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onOk = (name, location) => {
    console.log(name);
    console.log(location);
  };


  return (
    <div className="flex flex-col p-6">
      <div className="text-xl border-b">Pengajuan Bengkel Perorangan</div>
      <Table 
        dataSource={data} 
        columns={columns} 
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, article) => {
            var keys = [];
            if (expanded) {
              keys.push(article._id);
            }

            setExpandedRowKeys(keys);
          },
          expandedRowRender: (record) => {
            let file = record['file_ktp'] as string;
            let isImage = false;
            if(file){
              const arr = file.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImage = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImage = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImage = true;
                }
              }
            }
            let content = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['file_ktp']}`}>View</a>;
            if(isImage){
              content = <Image
              width={200}
              src={`https://ws.nagara.co.id/api/files/download/${record['file_ktp']}`}
            />
            }
            
            return (
              <div style={{ marginLeft: 50 }}>
                <div style={{borderBottom: '1px solid gray', fontWeight: 500}}>List Bengkel</div>
                <table>
                  <tbody>
                    {
                      record.bengkel.map((bengkel, idx) => {
                        return (
                          <React.Fragment key={idx}>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['name'].toUpperCase()}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['telp']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama Penanggung Jawab</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['nama_penanggung_jawab']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp Penanggung Jawab</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['telp_penanggung_jawab']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kota</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kota']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kecamatan</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kecamatan']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kelurahan</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kelurahan']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['alamat']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kodepos</td>
                            <td style={{borderBottom: '1px solid gray'}}>{bengkel['kodeposl']}</td>
                          </tr>
                          <tr>
                            <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Posisi</td>
                            <td style={{borderBottom: '1px solid gray'}}>
                            <Button onClick={() => {
                              setNamePicker(bengkel['name'].toUpperCase())
                              setIsPickerOpen(true);
                              setPosisiBengkel([parseFloat(bengkel['latitude']), parseFloat(bengkel['longitude'])])
                            }} style={{color: 'green'}} icon={<GlobalOutlined />} type="dashed">Show Map</Button>
                              {bengkel['latitude']} {bengkel['longitude']}
                              </td>
                          </tr>
                          </React.Fragment>
                        )
                    })
                    }
                  </tbody>
                </table>
                <Divider />
                <table>
                  <tbody>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['name']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>NIK Pengurus</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['nik_pengurus']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>KTP</td>
                      <td style={{borderBottom: '1px solid gray'}}>
                        {content}
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['telp']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Email</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['email']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat Sesuai KTP</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kota</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['ktp']['kota']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kecamatan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['ktp']['kecamatan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kelurahan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['ktp']['kelurahan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>alamat</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['ktp']['alamat']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>alamat</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['ktp']['kodepos']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Alamat Domisili</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kota</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['domisili']['kota']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kecamatan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['domisili']['kecamatan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kelurahan</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['domisili']['kelurahan']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>alamat</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['domisili']['alamat']}</td>
                            </tr>
                            <tr>
                              <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>kodepos</td>
                              <td style={{borderBottom: '1px solid gray'}}>{record['domisili']['kodepos']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                
              </div>
            )
          }
        }}
      />
      <IMapPicker
        isModalOpen={isPickerOpen}
        setIsModalOpen={setIsPickerOpen}
        name={namePicker}
        posisi={posisiBengkel}
        onOk={onOk}
      />
    </div>
  )
}
