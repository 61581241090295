import React,{useEffect, useState} from 'react'
import { Table, Image } from 'antd'
import superagent from 'superagent'

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    }
  ]

export function Users() {
    const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData();
  }, [])

  const loadData = () => {
    setLoading(true)
    let url = '/api/api/users/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/users/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          for(let i=0;i<body.data.length;i++){
            body.data[i].key = body.data[i]._id;
          }
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <div className="flex flex-col p-6">
          <div className="text-xl border-b">Users</div>
          <Table 
            dataSource={data} 
            columns={columns} 
            >
            </Table>
    </div>
        
  )
}