import React, { useState, useEffect } from 'react'
import superagent from 'superagent'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Input, Button, Textarea, Checkbox } from '@material-tailwind/react'
import produce from 'immer'
import Icon from '@mdi/react'
import { mdiCamera, mdiCloseBox } from '@mdi/js'
import * as yup from 'yup'
import { ResultApi, ParamsData } from '../../helper'
import Datepicker from 'react-tailwindcss-datepicker'
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types'

let ids = 1

export interface IUpload {
  id: number
  file?: File
  imgSrc?: string
  fromServer?: IUploadResult
}

export interface IUploadResult {
  name: string
  filename: string
}

function addWeeks(date, weeks) {
  date.setDate(date.getDate() + 7 * weeks)
  return date
}

export function InsertCourse() {
  const navigate = useNavigate()
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [imageDelete, setImageDelete] = useState<Array<IUploadResult>>([])
  const [dataUpload, setDataUpload] = useState<Array<IUpload>>([
    {
      id: ids,
      file: undefined,
      imgSrc: undefined,
    },
  ])
  const [dateValue, setDateValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: addWeeks(new Date(), 1),
  })

  const handleDateValueChange = newValue => {
    console.log('newValue:', newValue)
    setDateValue(newValue)
  }

  const [form, setForm] = useState({
    name: {
      value: '',
      error: false,
      errorText: '',
    },
    description: {
      value: '',
      error: false,
      errorText: '',
    },
    price: {
      value: 0,
      error: false,
      errorText: '',
    },
    active: {
      value: true,
      error: false,
      errorText: '',
    },
    images: {
      value: '',
      error: false,
      errorText: '',
    },
  })

  useEffect(() => {
    if (id) {
      if (id != 'new') {
        console.log('courseId ', id)
        loadData()
      }
    }
  }, [id])

  const loadData = () => {
    let url = '/api/api/course/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/course/retrieve'
    }
    superagent
      .post(url)
      .send({
        filter: {
          id: id,
        },
      })
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          console.log(body)
          let dt = body.data[0]
          setForm(prevState => ({
            ...prevState,
            name: {
              ...prevState.name,
              value: dt.name,
              error: false,
              errorText: '',
            },
            description: {
              ...prevState.description,
              value: dt.description,
              error: false,
              errorText: '',
            },
            price: {
              ...prevState.price,
              value: dt.price,
              error: false,
              errorText: '',
            },
            active: {
              ...prevState.active,
              value: dt.active,
              error: false,
              errorText: '',
            },
          }))

          setDateValue(prevState => ({
            ...prevState,
            startDate: new Date(dt.startDate),
            endDate: new Date(dt.endDate),
          }))

          const nextState = produce(dataUpload, draftState => {
            draftState.splice(1, draftState.length) //

            for (let i = 0; i < dt.images.length; i++) {
              let idx = draftState.findIndex(d => d.id == ids)
              if (idx > -1) {
                draftState[idx].file = undefined
                draftState[idx].fromServer = {
                  filename: dt.images[i].filename,
                  name: dt.images[i].name,
                }
                draftState[idx].imgSrc =
                  'https://img.nagara.co.id/nagara/' + dt.images[i].filename
              }

              ids++
              draftState.push({
                id: ids,
                file: undefined,
                imgSrc: undefined,
              })
            }
          })
          setDataUpload(nextState)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const dropHandler = (ev, up) => {
    ev.preventDefault()
    if (ev.dataTransfer) {
      let tfile: File | undefined = undefined
      if (ev.dataTransfer.items) {
        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            let t = ev.dataTransfer.items[i].getAsFile()
            if (t) {
              tfile = t
            }
            //console.log('1 ... file[' + i + '].name = ' + file.name);
          }
        }
      } else {
        for (var i = 0; i < ev.dataTransfer.files.length; i++) {
          //console.log('2 ... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
          tfile = ev.dataTransfer.files[i]
        }
      }
      if (tfile) {
        const nextState = produce(dataUpload, draftState => {
          let idx = draftState.findIndex(d => d.id == up.id)
          if (idx > -1) {
            draftState[idx].file = tfile
            let u = window.URL.createObjectURL(tfile as File)
            draftState[idx].imgSrc = u
          }

          ids++
          draftState.push({
            id: ids,
            file: undefined,
            imgSrc: undefined,
          })
        })
        setDataUpload(nextState)

        /*setFile(tfile);
            let u = window.URL.createObjectURL(tfile);
            setImgSrc(u);*/
      }
    }
  }

  function dragOverHandler(ev, up) {
    ev.preventDefault()
  }

  let onFileChange = (e, up) => {
    if (e.target.files) {
      let tfile = e.target.files[0]

      const nextState = produce(dataUpload, draftState => {
        let idx = draftState.findIndex(d => d.id == up.id)
        if (idx > -1) {
          draftState[idx].file = tfile
          let u = window.URL.createObjectURL(tfile)
          draftState[idx].imgSrc = u
        }

        ids++
        draftState.push({
          id: ids,
          file: undefined,
          imgSrc: undefined,
        })
      })
      setDataUpload(nextState)
    }
  }

  const onClickDeleteImage = (ev, up) => {
    ev.preventDefault()

    const nextState = produce(imageDelete, draftState => {
      draftState.push({
        name: up.fromServer.name,
        filename: up.fromServer.filename,
      })
    })
    setImageDelete(nextState)

    const nextState2 = produce(dataUpload, draftState => {
      let idx = draftState.findIndex(d => d.id == up.id)
      if (idx > -1) {
        draftState.splice(idx, 1)
      }
    })
    setDataUpload(nextState2)

    console.log(up)
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({
      ...prevState,
      name: {
        ...prevState.name,
        value: e.target.value,
        error: false,
        errorText: '',
      },
    }))
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm(prevState => ({
      ...prevState,
      description: {
        ...prevState.description,
        value: e.target.value,
        error: false,
        errorText: '',
      },
    }))
  }

  const onPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let pr = parseInt(e.target.value)
    setForm(prevState => ({
      ...prevState,
      price: {
        ...prevState.price,
        value: pr,
        error: false,
        errorText: '',
      },
    }))
  }

  let sendToServer = (): Promise<Array<IUploadResult>> => {
    return new Promise((resolve, reject) => {
      let isHasUpload = false
      const formData = new FormData()
      for (let i = 0; i < dataUpload.length; i++) {
        if (dataUpload[i].file) {
          formData.append(
            dataUpload[i].file?.name as string,
            dataUpload[i].file as Blob,
            dataUpload[i].file?.name as string
          )
          isHasUpload = true
        }
      }

      if (!isHasUpload) {
        resolve([])
      }

      let url = '/api/api/files/upload'
      if (process.env.NODE_ENV === 'production') {
        url = '/api/files/upload'
      }
      superagent
        .post(url)
        .send(formData)
        .on('progress', function (ev) {
          console.log('Percentage done: ', ev.percent)
        })
        .end((err, res) => {
          if (err) {
            console.log({ error: { message: 'error post person' } })
            reject(err)
            return
          }
          //console.info('upload ', res.body);
          if (res.body.result == 200) {
            const propertyValues = Object.entries(res.body.data)
            let hasil: Array<IUploadResult> = []
            for (let i = 0; i < propertyValues.length; i++) {
              hasil.push({
                name: propertyValues[i][0] as string,
                filename: propertyValues[i][1] as string,
              })
            }
            console.info('upload ', hasil)
            resolve(hasil)
            //loadData();
            //loadUser();
            //alert('sukses')
            //let u = window.URL.createObjectURL(file);
            //setLogo_email(u);
          } else {
            let ok = false
            if (res.body.error) {
              if (res.body.error[0]) {
                console.log(res.body.error[0].message)
                ok = true
              }
            }
            if (!ok) console.error('gagal')

            reject('gagal')
          }
        })
    })
  }

  const onsubmit = () => {
    //console.log(dateValue.startDate, " ", new Date(dateValue.startDate) );
    //console.log(dateValue.endDate, " ", new Date(dateValue.endDate));
    //return;
    //sendToServer();
    //return;
    const schema = yup.object().shape({
      name: yup.string().min(4).required(),
      description: yup.string().min(20).required(),
      price: yup.number().min(1000, 'minimal seribu').required(),
      active: yup.boolean().required(),
      startDate: yup.date().required(),
      endDate: yup.date().required(),
      images: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number(),
            file: yup.mixed().nullable(),
            imgSrc: yup.string(),
          })
        )
        .min(1, 'minimal satu image'),
    })
    schema
      .validate({
        name: form.name.value,
        description: form.description.value,
        price: form.price.value,
        active: form.active.value,
        images: dataUpload,
        startDate: new Date(dateValue?.startDate as Date),
        endDate: new Date(dateValue?.endDate as Date),
      })
      .then(async val => {
        setLoading(true)

        let imgs = await sendToServer()
        let s: Partial<any> = {
          id: id,
          name: val.name,
          description: val.description,
          price: val.price,
          active: val.active,
          startDate: val.startDate,
          endDate: val.endDate,
          images: imgs,
        }

        if (id != 'new') {
          let tdi: Array<IUploadResult> = []
          for (let i = 0; i < dataUpload.length; i++) {
            if (dataUpload[i].fromServer) {
              tdi.push(dataUpload[i].fromServer as IUploadResult)
            }
          }
          Array.prototype.push.apply(imgs, tdi)
          s.images = imgs
          s.imagesDelete = imageDelete
        }

        let url = '/api/api/course/insert'
        if (process.env.NODE_ENV === 'production') {
          url = '/api/course/insert'
        }
        superagent
          .post(url)
          .send(s)
          .then(res => {
            const body = res.body as ResultApi
            if (body.result == 200) {
              navigate('/course')
            } else {
              //alert('Submit failed');
            }
            setLoading(false)
          })
          .catch(err => {
            console.error(err)
          })
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'name') {
            setForm(prevState => ({
              ...prevState,
              name: {
                ...prevState.name,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'description') {
            setForm(prevState => ({
              ...prevState,
              description: {
                ...prevState.description,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'price') {
            setForm(prevState => ({
              ...prevState,
              price: {
                ...prevState.price,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'images') {
            setForm(prevState => ({
              ...prevState,
              images: {
                ...prevState.images,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  return (
    <div className="m-4 p-4 bg-white">
      <div className="flex flex-row justify-between text-lg border-b mb-4">
        <div>New Course</div>
        <div className="mb-1">
          <Button
            onClick={() => navigate('/course')}
            className="mr-2"
            color="red"
            size="sm"
          >
            Cancel
          </Button>
          <Button onClick={() => onsubmit()} size="sm">
            Save
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-6 mb-8">
        <div className="w-1/2">
          <Input
            onChange={onNameChange}
            label="Course Title"
            value={form.name.value}
            error={form.name.error}
          />
          <div
            className={classNames(
              { hidden: !form.name.error },
              'text-xs text-red-500 p-1'
            )}
          >
            {form.name.errorText}
          </div>
        </div>
        <div className="w-full">
          <Textarea
            onChange={onDescriptionChange}
            label="Course Description"
            value={form.description.value}
            error={form.description.error}
          />
          <div
            className={classNames(
              { hidden: !form.description.error },
              'text-xs text-red-500 p-1'
            )}
          >
            {form.description.errorText}
          </div>
        </div>

        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-1/3 flex-none">
            <div className="font-semibold text-gray-700">Foto Courses</div>
            <div className="text-sm text-gray-500">
              Format gambar .jpg, .jpeg, atau .png dengan dimensi 300 x 300
              pixel, ukuran file maks. 2.5 MB.
            </div>
            <div
              className={classNames(
                { hidden: !form.images.error },
                'text-xs text-red-500 p-1'
              )}
            >
              {form.images.errorText}
            </div>
          </div>

          <div className="ml-12 flex overflow-x-scroll p-4">
            <div style={{ minWidth: 'min-content' }} className="flex flex-row">
              {dataUpload.map((up, idx) => {
                return (
                  <label
                    key={idx}
                    onDrop={e => dropHandler(e, up)}
                    onDragOver={e => dragOverHandler(e, up)}
                    className={classNames(
                      'mr-6 relative border flex flex-col justify-center items-center w-28 h-28 border hover:border-green-300 cursor-pointer'
                    )}
                  >
                    <img
                      style={{ width: '100%', objectFit: 'cover' }}
                      className="p-1 overflow-hidden"
                      src={up.imgSrc}
                    />
                    <div
                      className={classNames(
                        { hidden: up.imgSrc },
                        'absolute text-sm left-9 top-10 text-gray-400'
                      )}
                    >
                      <Icon
                        className={classNames('')}
                        path={mdiCamera}
                        title="Upload"
                        size={1.4}
                      />
                    </div>
                    {dataUpload.length > 0 && (
                      <div
                        className={classNames({
                          hidden: idx == dataUpload.length - 1,
                        })}
                        onClick={e => onClickDeleteImage(e, up)}
                      >
                        <Icon
                          className={classNames(
                            'right-0 top-0 absolute text-red-400 -mr-2 -mt-2 hover:text-red-600 cursor-pointer'
                          )}
                          path={mdiCloseBox}
                          title="Close"
                          size={1.0}
                        />
                      </div>
                    )}
                    <input
                      onChange={e => onFileChange(e, up)}
                      className="hidden"
                      type="file"
                    />
                  </label>
                )
              })}
            </div>
          </div>
        </div>

        <div className="w-1/2">
          <Input
            onChange={onPriceChange}
            label="Course Price"
            type="number"
            value={form.price.value}
            error={form.price.error}
          />
          <div
            className={classNames(
              { hidden: !form.price.error },
              'text-xs text-red-500 p-1'
            )}
          >
            {form.price.errorText}
          </div>
        </div>

        <div className="flex flex-col w-2/3">
          <div className="text-xs">Tanggal</div>
          <div
            className={classNames(`mt-1 block w-full bg-white border border-gray-300 rounded-md md:text-sm text-5xl shadow-sm placeholder-gray-400
        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
        disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
        invalid:border-pink-500 invalid:text-pink-600
        focus:invalid:border-pink-500 focus:invalid:ring-pink-500`)}
          >
            <Datepicker
              value={dateValue}
              displayFormat={'dddd, D MMMM, YYYY'}
              onChange={handleDateValueChange}
            />
          </div>
        </div>

        <div className="w-72">
          <Checkbox label="Active" checked={form.active.value} />
        </div>
      </div>
    </div>
  )
}
