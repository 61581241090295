import './assets/css/styles.css'

import React, { useEffect, useState } from 'react'
import LayoutAdmin from './components/layout'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Dashboard } from './pages/dashboard'
import { Bengkel } from './pages/bengkel'
import { Course } from './pages/course'
import { InsertCourse } from './pages/course/insert'
import { Product } from './pages/product'
import { PengajuanKonversi } from './pages/konversi'
import { PengajuanBengkel } from './pages/bengkel'
import { InsertProduct } from './pages/product/insert'
import { ProductCategory } from './pages/product/category'
import { InsertCategoryProduct } from './pages/product/category/insert'
import { Certificates } from './pages/certificates'
import { InsertCertificate } from './pages/certificates/insert'
import { BengkelDetail } from './pages/bengkel/detail'
import { ErrorPage } from './pages/404'
import { LoginPage, RegisterPage } from './pages/auth'
import superagent from 'superagent'
import { ResultApi, ParamsData } from './helper'
import { store, RootState } from './libs/redux/store'
import { Provider } from 'react-redux'
import { setUserSession } from './libs/redux/global'
import { PengajuanBengkelPerorangan } from './pages/bengkel-new'
import { PengajuanBengkelBadanUsaha } from './pages/bengkel-new'
import { Users } from './pages/users';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutAdmin />,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: 'bengkel',
        element: <Bengkel />,
      },
      {
        path: 'pengajuan-bengkel',
        element: <PengajuanBengkel />,
      },
      {
        path: 'pengajuan-bengkel-badanusaha',
        element: <PengajuanBengkelBadanUsaha />,
      },
      {
        path: 'pengajuan-bengkel-perorangan',
        element: <PengajuanBengkelPerorangan />,
      },
      {
        path: 'pengajuan',
        element: <PengajuanKonversi />,
      },
      {
        path: 'course/insert/:id',
        element: <InsertCourse />,
      },
      {
        path: 'course',
        element: <Course />,
      },
      {
        path: 'product/category/insert/:id',
        element: <InsertCategoryProduct />,
      },
      {
        path: 'product/category',
        element: <ProductCategory />,
      },
      {
        path: 'product/insert/:id',
        element: <InsertProduct />,
      },
      {
        path: 'product',
        element: <Product />,
      },
      {
        path: 'certificates/insert/:id',
        element: <InsertCertificate />,
      },
      {
        path: 'certificates',
        element: <Certificates />,
      },
      {
        path: 'bengkel-detail/:id',
        element: <BengkelDetail />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      { path: '*', element: <ErrorPage /> },
    ],
  },
])

const routerNotLogin = createBrowserRouter([
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
    ],
  },
  { path: '*', element: <LoginPage /> },
])

export function App() {
  const [isLogin, setIsLogin] = useState(false)
  useEffect(() => {
    check()
  }, [])

  const check = () => {
    let url = '/api/api/auth/check'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/auth/check'
    }
    console.log('url ', url)
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body as ResultApi
        if (body.result == 200) {
          //console.log(body.data)
          let type = '';
          if(body.data){
            type = body.data['type'];
          }
          if(type == 'admin'){
            store.dispatch(
              setUserSession({
                name: body.data?.name as string,
                email: body.data?.email as string,
                role: body.data?.role as string,
                id: body.data?._id as string,
              })
            )
            setIsLogin(true)
          }
          
        } else {
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <Provider store={store}>
      <RouterProvider router={isLogin ? router : routerNotLogin} />
    </Provider>
  )
}
