import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import CurrencyInput from 'react-currency-input-field'

export interface InputProps {
  disable?: boolean
  value?: string
  type: string
  title: string
  placeholder?: string
  name: string
  error?: boolean
  errorText?: string
  className?: string
  textRight?: string
  loader?: boolean
  onChange?: (name: string, value: string) => void
  onClickTextRight?: () => void
}

export function Input(props: InputProps) {
  const [title, setTitle] = useState('')
  const [name, setName] = useState('')
  const [type, setType] = useState('text')
  const [value, setValue] = useState(props.value)
  const [error, setError] = useState(false)
  const [disable, setDisable] = useState(false)
  const [loader, setLoader] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [className, setclassName] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [textRight, setTextRight] = useState('')

  useEffect(() => {
    if (props.className) {
      setclassName(props.className)
    }
  }, [props.className])

  useEffect(() => {
    if (props.disable != undefined) {
      setDisable(props.disable)
    }
  }, [props.disable])

  useEffect(() => {
    if (props.loader != undefined) {
      setLoader(props.loader)
    }
  }, [props.loader])

  useEffect(() => {
    setType(props.type)
  }, [props.type])

  useEffect(() => {
    setTitle(props.title)
  }, [props.title])

  useEffect(() => {
    if (props.placeholder != undefined) {
      setPlaceholder(props.placeholder)
    }
  }, [props.placeholder])

  useEffect(() => {
    setName(props.name)
  }, [props.name])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    if (props.error != undefined) {
      setError(props.error)
    }
  }, [props.error])

  useEffect(() => {
    if (props.errorText != undefined) {
      setErrorText(props.errorText)
    }
  }, [props.errorText])

  useEffect(() => {
    if (props.textRight != undefined) {
      setTextRight(props.textRight)
    }
  }, [props.textRight])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (props.onChange) {
      props.onChange(name, e.target.value)
    }
  }

  const onChangeMoney = (
    value: string | undefined,
    name: string | undefined
  ) => {
    setValue(value)
    if (props.onChange) {
      if (name && value) {
        props.onChange(name, value)
      }
    }
  }

  const onClickTextRight = () => {
    if (props.onClickTextRight) {
      props.onClickTextRight()
    }
  }

  const getInput = () => {
    if (type == 'money') {
      return (
        <CurrencyInput
          placeholder={placeholder}
          value={value}
          name={name}
          decimalsLimit={2}
          className={classNames(`mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md md:text-sm text-5xl shadow-sm placeholder-gray-400
          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
          disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
          invalid:border-pink-500 invalid:text-pink-600
          focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ${className}`)}
          onValueChange={(value, name) => onChangeMoney(value, name)}
        />
      )
    } else {
      return (
        <input
          required={error}
          disabled={disable}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          className={classNames(`mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md md:text-sm text-5xl shadow-sm placeholder-gray-400
        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
        disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
        invalid:border-pink-500 invalid:text-pink-600
        focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ${className}`)}
        />
      )
    }
  }

  return (
    <div
      className={classNames(
        className + ' flex flex-col w-full md:mt-2 mt-8 md:mb-2 mb-4'
      )}
    >
      <label className="block">
        <span className="md:mt-0 mt-8 md:mb-0 mb-4 block md:text-sm text-5xl font-medium text-gray-700">
          {title}
        </span>
        <div className="relative mt-1 block w-full">
          {getInput()}
          <div
            onClick={() => onClickTextRight()}
            className={classNames(
              { hidden: disable && loader },
              'h-full flex flex-row items-center absolute top-0 right-0 text-blue-600 text-xs mr-2 cursor-pointer hover:text-blue-400'
            )}
          >
            {textRight}
          </div>
          <div
            className={classNames(
              { hidden: !loader },
              'mt-2 mr-2 opacity-50 absolute top-0 right-0 w-6 h-6'
            )}
          >
            <svg
              className="animate-spin fill-transparent"
              strokeWidth="14"
              strokeDasharray="220"
              stroke="#7f8c8d"
              strokeDashoffset="55"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="40" />
            </svg>
          </div>
        </div>
      </label>

      <label className={classNames({ hidden: !error }, 'label')}>
        <span className="md:text-xs text-4xl text-red-400">{errorText}</span>
      </label>
    </div>
  )
}
