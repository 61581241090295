import React, { useEffect, useState } from 'react'
import {
  useTable,
  Column,
  useSortBy,
  SortingRule,
  useRowSelect,
  TableToggleAllRowsSelectedProps,
  CellProps,
  usePagination,
} from 'react-table'
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'

export interface Cols {
  [key: string]: string | number | Date | boolean | Array<any>
}

export interface TableProps {
  data: Cols[]
  total: number
  page: number
  limit: number
  columns: Column<Cols>[]
  onPageIndex?: (idx: number) => void
  onPageSize?: (idx: number) => void
  onSort?: (s: SortingRule<Cols>[]) => void
  onSelect?: (s: Record<string, boolean>) => void
}

function Checkbox(props: TableToggleAllRowsSelectedProps) {
  const render = () => {
    const { indeterminate, ...rest } = props
    return (
      <input
        type="checkbox"
        ref={input => {
          if (input) {
            if (indeterminate != undefined) {
              input.indeterminate = indeterminate
            }
          }
        }}
        {...rest}
      />
    )
  }

  return render()
}

export function Table(props: TableProps) {
  const [queryPageIndex, setQueryPageIndex] = useState(0)
  const [queryPageSize, setQueryPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const tableInstance = useTable(
    {
      columns: props.columns,
      data: props.data,
      manualSortBy: true,
      disableSortRemove: true,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / queryPageSize),
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          /* eslint-disable react/prop-types */
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="w-1">
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: CellProps<Cols>) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    pageCount,
    page,
    state: { sortBy, selectedRowIds, pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance

  useEffect(() => {
    if (props.total) {
      setTotalCount(props.total)
    }
  }, [props.total])

  useEffect(() => {
    if (props.page) {
      setQueryPageIndex(props.page)
    }
  }, [props.page])

  useEffect(() => {
    if (props.limit) {
      setQueryPageSize(props.limit)
    }
  }, [props.limit])

  useEffect(() => {
    if (props.onSort) {
      props.onSort(sortBy)
    }
  }, [sortBy])

  useEffect(() => {
    //console.log('pageIndex ', pageIndex);
    setQueryPageIndex(pageIndex)
    if (props.onPageIndex) {
      props.onPageIndex(pageIndex)
    }
  }, [pageIndex])

  useEffect(() => {
    //console.log('pageSize ', pageSize);
    setQueryPageSize(pageSize)
    gotoPage(0)
    if (props.onPageSize) {
      props.onPageSize(pageSize)
    }
  }, [pageSize, gotoPage])

  useEffect(() => {
    if (props.onSelect) {
      props.onSelect(selectedRowIds)
    }
  }, [selectedRowIds])

  return (
    <div className="flex flex-col h-96 justify-between border border-gray-200">
      <div className="w-full overflow-auto">
        <table className="" {...getTableProps()}>
          <thead className="sticky top-0 left-0 right-0">
            {headerGroups.map(headerGroup => {
              const { key, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps()
              return (
                <tr key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const { key, ...restHeaderProps } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    ) //
                    return (
                      <th className="border" key={key} {...restHeaderProps}>
                        <div className="-mt-1 h-10 min-w-max flex flex-row justify-between items-center text-sm p-2 bg-gray-100">
                          {column.render('Header')}
                          <div className="ml-3">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronUpIcon className="w-4 text-gray-500" />
                              ) : (
                                <ChevronDownIcon className="w-4 text-gray-500" />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <tr
                  key={key}
                  {...restRowProps}
                  className={classNames({ 'bg-blue-50': row.isSelected })}
                >
                  {row.cells.map(cell => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <td className="border" key={key} {...restCellProps}>
                        <div
                          className={classNames(
                            { 'bg-blue-50': row.isSelected },
                            'min-w-max p-2 bg-white text-sm text-gray-500'
                          )}
                        >
                          {cell.render('Cell')}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex flex-row justify-between p-1 border-t text-xs">
        <div className="flex flex-row">
          <div
            onClick={() => {
              if (canPreviousPage) gotoPage(0)
            }}
            className={classNames(
              {
                'cursor-pointer hover:bg-blue-100 text-gray-700':
                  canPreviousPage,
              },
              { 'text-gray-400 bg-gray-200': !canPreviousPage },
              'p-2 border ml-1 mr-1 rounded-sm'
            )}
          >
            <ChevronDoubleLeftIcon className="w-3" />
          </div>

          <div
            onClick={() => {
              if (canPreviousPage) previousPage()
            }}
            className={classNames(
              {
                'cursor-pointer hover:bg-blue-100 text-gray-700':
                  canPreviousPage,
              },
              { 'text-gray-400 bg-gray-200': !canPreviousPage },
              'p-2 border ml-1 mr-1 rounded-sm'
            )}
          >
            <ChevronLeftIcon className="w-3" />
          </div>

          <div
            onClick={() => {
              if (canNextPage) nextPage()
            }}
            className={classNames(
              { 'cursor-pointer hover:bg-blue-100 text-gray-700': canNextPage },
              { 'text-gray-400 bg-gray-200': !canNextPage },
              'p-2 border ml-1 mr-1 rounded-sm'
            )}
          >
            <ChevronRightIcon className="w-3" />
          </div>

          <div
            onClick={() => {
              if (canNextPage) gotoPage(pageCount - 1)
            }}
            className={classNames(
              { 'cursor-pointer hover:bg-blue-100 text-gray-700': canNextPage },
              { 'text-gray-400 bg-gray-200': !canNextPage },
              'p-2 border ml-1 mr-1 rounded-sm'
            )}
          >
            <ChevronDoubleRightIcon className="w-3" />
          </div>
        </div>

        <div className="p-1">
          Page{' '}
          <em>
            {pageIndex + 1} of {pageOptions.length}
          </em>
        </div>

        <div className="flex flex-row items-center">
          <div className="mr-2">Go to page:</div>
          <input
            className="p-1 border w-16"
            type="number"
            value={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          />
        </div>

        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
