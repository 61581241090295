import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'

export function DialogModal(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState('w-1/2')

  useEffect(() => {
    //if(props.open != undefined) {
    //console.log('isOpen ', isOpen)
    //console.log('props.open ', props.open)
    //if(props.open != isOpen) {
    //console.log('triger ', props.open)
    setIsOpen(props.open)
    //}
    //}
  }, [props.open])

  useEffect(() => {
    if (props.width) {
      setWidth(props.width)
    }
  }, [props.width])

  const onClose = name => {
    setIsOpen(false)
    if (props.onClose) {
      props.onClose(name)
    }
  }

  const render = () => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => onClose('nothing')}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 p-2"
                  >
                    {props.title}
                  </Dialog.Title>

                  <div className="p-2 border-b border-t">{props.children}</div>

                  <div className="flex flex-row-reverse mt-4 ml-2 mb-4">
                    {props.buttons.map((btn, idx) => {
                      return (
                        <button
                          key={idx}
                          type="button"
                          className="mr-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                          onClick={() => onClose(btn.name)}
                        >
                          {btn.title}
                        </button>
                      )
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }

  return render()
}
