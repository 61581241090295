import React, { useState, useEffect } from 'react'
import superagent from 'superagent'
import { Table } from 'antd'

const columns = [
  {
    title: 'Name',
    dataIndex: 'nama_bengkel',
    key: 'nama_bengkel',
  },
  {
    title: 'Kota',
    dataIndex: 'kota_bengkel',
    key: 'kota_bengkel',
  },
  {
    title: 'Alamat',
    dataIndex: 'alamat_bengkel',
    key: 'alamat_bengkel',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
]

export function PengajuanBengkel() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    let url = '/api/api/bengkel/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/bengkel/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <div className="flex flex-col p-6">
      <div className="text-xl border-b">Pengajuan Bengkel</div>
      <Table dataSource={data} columns={columns} />;
    </div>
  )
}
