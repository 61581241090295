import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

export interface ButtonProps {
  title: string
  className?: string
  disable?: boolean
  onClick?: () => void
}
export function Button(props: ButtonProps) {
  const [className, setclassName] = useState('')
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (props.className) {
      setclassName(props.className)
    }
  }, [props.className])

  useEffect(() => {
    if (props.disable != undefined) {
      setDisable(props.disable)
    }
  }, [props.disable])

  const onClick = () => {
    if (props.onClick && !disable) {
      props.onClick()
    }
  }

  return (
    <div
      onClick={onClick}
      className={classNames(
        { 'cursor-pointer hover:bg-blue-500 bg-blue-400 text-white': !disable },
        { 'bg-gray-300 text-gray-100': disable },
        `md:text-sm text-5xl text-center w-full md:p-2 p-6 md:mt-2 mt-8 ${className}`
      )}
    >
      {props.title}
    </div>
  )
}
