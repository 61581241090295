import React, { useState } from 'react'
import { Input, Button } from '../../components'
import img_avatar from '../../assets/images/userprofile.png'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import classNames from 'classnames'
import superagent from 'superagent'
import { ResultApi, ParamsData } from '../../helper'

export function LoginPage() {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    email: {
      value: '',
      error: false,
      errorText: '',
    },
    password: {
      value: '',
      error: false,
      errorText: '',
    },
  })

  const onEmailChange = (name: string, value: string) => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
    })
    schema
      .validate({ email: value })
      .then(val => {
        setForm(prevState => ({
          ...prevState,
          email: {
            ...prevState.email,
            value: val.email,
            error: false,
            errorText: '',
          },
        }))
      })
      .catch(function (err) {
        const path = err.path as string
        if (path) {
          if (path == 'email') {
            setForm(prevState => ({
              ...prevState,
              email: {
                ...prevState.email,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const onPasswordChange = (name: string, value: string) => {
    const schema = yup.object().shape({
      password: yup.string().min(7).required(),
    })
    schema
      .validate({ password: value })
      .then(val => {
        setForm(prevState => ({
          ...prevState,
          password: {
            ...prevState.password,
            value: val.password,
            error: false,
            errorText: '',
          },
        }))
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'password') {
            setForm(prevState => ({
              ...prevState,
              password: {
                ...prevState.password,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  const onSubmit = () => {
    const schema = yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().min(7).required(),
      remember: yup.boolean(),
    })
    schema
      .validate({
        email: form.email.value,
        password: form.password.value,
        remember: true,
      })
      .then(val => {
        setLoading(true)
        let url = '/api/api/auth/signin'
        if (process.env.NODE_ENV === 'production') {
          url = '/api/auth/signin'
        }
        superagent
          .post(url)
          .send(val)
          .then(res => {
            const body = res.body as ResultApi
            if (body.result == 200) {
              location.href = '/'
            } else {
              //alert('Submit failed');
            }
            setLoading(false)
          })
          .catch(err => {
            console.error(err)
          })
      })
      .catch(function (err) {
        if (err.path) {
          if (err.path == 'email') {
            setForm(prevState => ({
              ...prevState,
              email: {
                ...prevState.email,
                error: true,
                errorText: err.message,
              },
            }))
          } else if (err.path == 'password') {
            setForm(prevState => ({
              ...prevState,
              name: {
                ...prevState.password,
                error: true,
                errorText: err.message,
              },
            }))
          }
        }
      })
  }

  return (
    <div className="flex w-screen h-screen justify-center items-center overflow-y-auto">
      <div
        className={classNames(
          { hidden: !loading },
          'fixed z-20 flex flex-row w-screen h-screen justify-center items-center fixed inset-0 bg-black opacity-30'
        )}
      >
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="flex flex-col md:border p-4 md:w-2/6 w-full">
        <div className="flex flex-col justify-center items-center w-full">
          <img
            className="rounded-full w-16"
            src={img_avatar}
            alt="Logo Pinbeli"
          />
        </div>
        <div className="text-green-500 font-bold text-center text-base border-b mb-4 p-2">
          SIGN IN
        </div>
        <Input
          name="email"
          title="email"
          type="text"
          value={form.email.value}
          error={form.email.error}
          errorText={form.email.errorText}
          onChange={onEmailChange}
          placeholder="your email"
          disable={loading}
        ></Input>
        <Input
          name="password"
          title="password"
          type="password"
          value={form.password.value}
          error={form.password.error}
          errorText={form.password.errorText}
          onChange={onPasswordChange}
          placeholder="your password"
          disable={loading}
        ></Input>
        <div className="flex flex-row w-full justify-center mt-8">
          <Button
            onClick={onSubmit}
            title="SIGN IN"
            className="rounded-md w-1/2"
          />
        </div>
        <Link to="/auth/register">
          <div className="mt-4 text-gray-500 hover:text-blue-500 cursor-pointer text-sm">
            Do not Have an account, please apply here
          </div>
        </Link>
        <div className="mt-2 text-gray-500 hover:text-blue-500 cursor-pointer text-sm">
          forget password ?
        </div>
      </div>
    </div>
  )
}
