import React, { useEffect, useState } from 'react'
import { Checkbox, Button, Popconfirm, Table, Image } from 'antd'
import superagent from 'superagent'
import {
  CheckCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';



export function PengajuanKonversi() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    loadData()
  }, [])

  const updateStatusData = (id, status) => {
    setLoading(true)
    let url = '/api/api/pengajuan/update'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/pengajuan/update'
    }
    superagent
      .post(url)
      .send({id, status})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          loadData();
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }
  
  const onConfirm = (data) => {
    updateStatusData(data._id, 'verified');
  }
  
  const onCancel = (data) => {
    updateStatusData(data._id, 'new');
  }

  const onChangeSelesai = (data) => {
    updateStatusData(data._id, 'selesai');
  }
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Pekerjaan',
      dataIndex: 'pekerjaan',
      key: 'pekerjaan',
    },
    {
      title: 'Kota',
      dataIndex: 'kota',
      key: 'kota',
    },
    {
      title: 'Type Motor',
      dataIndex: 'type_motor',
      key: 'type_motor',
    },
    {
      title: 'Bengkel',
      dataIndex: 'name_bengkel',
      key: 'name_bengkel',
    },
    {
      title: 'ALamat Bengkel',
      dataIndex: 'alamat_bengkel',
      key: 'alamat_bengkel',
    },
    {
      title: 'Tanggal',
      dataIndex: '',
      key: 'created_at',
      render: (data) => {
        return (
          <div>{new Intl.DateTimeFormat(['id'], {
            dateStyle: 'full',
            timeStyle: 'short'
          }).format(new Date(data.created_at))}</div>
        )
      }
    },
    {
      title: 'status',
      dataIndex: '',
      key: 'status',
      render: (data) => {
        if(data.status == 'verified'){
          let selesai = false;
          if(data.selesai){
            selesai = true;
          }
          return (
            <div className="flex flex-row align-center">
              <Popconfirm
                title="Rubah Status"
                description="Rubah Status Konversi Motor, Verified atau New"
                onConfirm={() => onConfirm(data)}
                onCancel={() => onCancel(data)}
                okText="Verified"
                okType="dashed"
                cancelText="New"
              >
                <Button style={{color: 'green'}} icon={<CheckCircleOutlined />} type="dashed">Verified</Button>
              </Popconfirm>
              <Checkbox className="ml-2" defaultChecked={selesai} onChange={() => onChangeSelesai(data)}>Selesai</Checkbox>
            </div>
          )
        } else {
          return (
            <Popconfirm
              title="Rubah Status"
              description="Rubah Status Konversi Motor, Verified atau New"
              onConfirm={() => onConfirm(data)}
              onCancel={() => onCancel(data)}
              okText="Verified"
              okType="dashed"
              cancelText="New"
            >
              <Button style={{color: 'orange'}} icon={<ClockCircleOutlined />} type="dashed">New</Button>
            </Popconfirm>
          )
        }
      }
    }
  ]

  const loadData = () => {
    setLoading(true)
    let url = '/api/api/pengajuan/retrieve'
    if (process.env.NODE_ENV === 'production') {
      url = '/api/pengajuan/retrieve'
    }
    superagent
      .post(url)
      .send({})
      .then(res => {
        const body = res.body
        if (body.result == 200) {
          for(let i=0;i<body.data.length;i++){
            body.data[i].key = body.data[i]._id;
          }
          setData(body.data)
        } else {
          //alert('Submit failed');
        }
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    <div className="flex flex-col p-6">
      <div className="text-xl border-b">Pengajuan Konversi</div>
      <Table 
        dataSource={data} 
        columns={columns} 
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, article) => {
            var keys = [];
            if (expanded) {
              keys.push(article._id);
            }

            setExpandedRowKeys(keys);
          },
          expandedRowRender: (record) => {
            let filektp = record['file_ktp'] as string;
            let isImagektp = false;
            if(filektp){
              const arr = filektp.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagektp = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagektp = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagektp = true;
                }
              }
            }
            let contentktp = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['file_ktp']}`}>View</a>;
            if(isImagektp){
              contentktp = <Image
              width={200}
              src={`https://ws.nagara.co.id/api/files/download/${record['file_ktp']}`}
            />
            }
            // -------
            let filetampakdepan = record['file_tampak_depan'] as string;
            let isImagetampakdepan = false;
            if(filetampakdepan){
              const arr = filetampakdepan.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagetampakdepan = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagetampakdepan = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagetampakdepan = true;
                }
              }
            }
            let contenttampakdepan = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['file_tampak_depan']}`}>View</a>;
            if(isImagetampakdepan){
              contenttampakdepan = <Image
              width={200}
              src={`https://ws.nagara.co.id/api/files/download/${record['file_tampak_depan']}`}
            />
            }
            // -------
            let filetampakbelakang = record['file_tampak_belakang'] as string;
            let isImagetampakbelakang = false;
            if(filetampakbelakang){
              const arr = filetampakbelakang.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagetampakbelakang = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagetampakbelakang = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagetampakbelakang = true;
                }
              }
            }
            let contenttampakbelakang = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['file_tampak_belakang']}`}>View</a>;
            if(isImagetampakbelakang){
              contenttampakbelakang = <Image
              width={200}
              src={`https://ws.nagara.co.id/api/files/download/${record['file_tampak_belakang']}`}
            />
            }
            // -------
            let filesampingkiri = record['file_samping_kiri'] as string;
            let isImagesampingkiri = false;
            if(filesampingkiri){
              const arr = filesampingkiri.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagesampingkiri = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagesampingkiri = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagesampingkiri = true;
                }
              }
            }
            let contentsampingkiri = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['file_samping_kiri']}`}>View</a>;
            if(isImagesampingkiri){
              contentsampingkiri = <Image
              width={200}
              src={`https://ws.nagara.co.id/api/files/download/${record['file_samping_kiri']}`}
            />
            }
            // -------
            let filesampingkanan = record['file_samping_kanan'] as string;
            let isImagesampingkanan = false;
            if(filesampingkanan){
              const arr = filesampingkanan.split('.');
              let extension = arr.pop();
              if(extension){
                if(extension.toLowerCase() == 'jpg'){
                  isImagesampingkanan = true;
                } else if(extension.toLowerCase() == 'jpeg'){
                  isImagesampingkanan = true;
                } else if(extension.toLowerCase() == 'png'){
                  isImagesampingkanan = true;
                }
              }
            }
            let contentsampingkanan = <a target="_blank" href={`https://ws.nagara.co.id/api/files/download/${record['file_samping_kanan']}`}>View</a>;
            if(isImagesampingkanan){
              contentsampingkanan = <Image
              width={200}
              src={`https://ws.nagara.co.id/api/files/download/${record['file_samping_kanan']}`}
            />
            }

            return (
              <div style={{ marginLeft: 50 }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Nama</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['name']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>NIK</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['nik']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>gender</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['gender']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Telp</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['telp']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Email</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['email']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Pekerjaan</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['pekerjaan']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Tempat Lahir</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['tempat_lahir']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Tanggal Lahir</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['tanggal_lahir']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>KTP</td>
                      <td style={{borderBottom: '1px solid gray'}}>{contentktp}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Tampak Depan</td>
                      <td style={{borderBottom: '1px solid gray'}}>{contenttampakdepan}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Tampak Belakang</td>
                      <td style={{borderBottom: '1px solid gray'}}>{contenttampakbelakang}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Samping Kiri</td>
                      <td style={{borderBottom: '1px solid gray'}}>{contentsampingkiri}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Samping Kanan</td>
                      <td style={{borderBottom: '1px solid gray'}}>{contentsampingkanan}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>No Polisi</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['no_polisi']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>No Rangka</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['no_rangka']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>No Mesin</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['no_mesin']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Surat Kendaraan</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['surat_kendaraan']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Status Pajak</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['status_pajak']}</td>
                    </tr>
                    <tr>
                      <td style={{borderBottom: '1px solid gray', fontWeight: 500}}>Kode PEngajuan</td>
                      <td style={{borderBottom: '1px solid gray'}}>{record['kode_pengajuan']}</td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            )
          }
        }}
      />
    </div>
  )
}
