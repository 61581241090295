import React, { useState } from 'react'
import Icon from '@mdi/react'
import {
  mdiHeartPlusOutline,
  mdiHeart,
  mdiViewDashboard,
  mdiHomeLightningBolt,
  mdiCogOutline,
  mdiAccount,
  mdiLogout,
  mdiBasket,
  mdiCalendarText,
} from '@mdi/js'

export function IconLib(props) {
  const [name] = useState(props.name)

  const render = () => {
    switch (name) {
      case 'mdiHeartPlusOutline':
        return <Icon path={mdiHeartPlusOutline} size={0.7} color={'#7D6E83'} />
      case 'mdiHeart':
        return <Icon path={mdiHeart} size={0.7} color={'#7D6E83'} />
      case 'mdiViewDashboard':
        return <Icon path={mdiViewDashboard} size={0.7} color={'#7D6E83'} />
      case 'mdiHomeLightningBolt':
        return <Icon path={mdiHomeLightningBolt} size={0.7} color={'#7D6E83'} />
      case 'mdiCogOutline':
        return <Icon path={mdiCogOutline} size={0.7} color={'#7D6E83'} />
      case 'mdiAccount':
        return <Icon path={mdiAccount} size={0.7} color={'#7D6E83'} />
      case 'mdiLogout':
        return <Icon path={mdiLogout} size={0.7} color={'#7D6E83'} />
      case 'mdiBasket':
        return <Icon path={mdiBasket} size={0.7} color={'#7D6E83'} />
      case 'mdiCalendarText':
        return <Icon path={mdiCalendarText} size={0.7} color={'#7D6E83'} />
      default:
        return <></>
    }
  }

  return render()
}
