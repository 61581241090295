import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './global'

export const store = configureStore({
  reducer: {
    globalState: globalReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
