import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISession {
  email: string
  name: string
  role: string
  id: string
}
const getSession = (): ISession | undefined => {
  return undefined
}

export const globalSlice = createSlice({
  name: 'globalState',
  initialState: {
    userSession: getSession(),
  },
  reducers: {
    setUserSession: (state, action: PayloadAction<ISession>) => {
      state.userSession = action.payload
    },
  },
})

export const { setUserSession } = globalSlice.actions

export default globalSlice.reducer
