import React, { useState, useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { PopUpMenu } from './popup-menu'
import { SideBarMenu } from './sidebar-menu'
import { DialogModal } from './dialog-modal'
import superagent from 'superagent'
import { Outlet } from 'react-router-dom'
import nagara from '../assets/images/nagara-kecil.png'
import userprofile from '../assets/images/userprofile.png'
import { useSelector } from 'react-redux'
import { RootState } from '../libs/redux/store'

export default function LayoutAdmin(props) {
  //const [user, setUser] = useState({})
  //const [name, setName] = useState('')
  const [showModalLogout, setShowModalLogout] = useState(false)
  const [sideBarSmall, setSideBarSmall] = useState(false)
  const user = useSelector((state: RootState) => state.globalState.userSession)

  const [menu, setMenu] = useState([
    {
      name: 'Dashboard',
      title: 'Beranda',
      icon: 'mdiViewDashboard',
      child: [
        {
          name: 'home',
          title: 'Home',
          link: '/',
          selected: true,
        },
      ],
    },
    {
      name: 'bengkel',
      title: 'Bengkel',
      icon: 'mdiHomeLightningBolt',
      child: [
        {
          name: 'list bengkel badanusaha',
          title: 'Pengajuan Badan Usaha',
          link: '/pengajuan-bengkel-badanusaha',
        },
        {
          name: 'list bengkel perorangan',
          title: 'Pengajuan Perorangan',
          link: '/pengajuan-bengkel-perorangan',
        },
      ],
    },
    {
      name: 'konversi',
      title: 'Konversi',
      icon: 'mdiHomeLightningBolt',
      child: [
        {
          name: 'Pengajuan Konversi',
          title: 'Pengajuan Konversi',
          link: '/pengajuan',
        },
      ],
    },
    {
      name: 'product',
      title: 'Product',
      icon: 'mdiBasket',
      child: [
        {
          name: 'list product',
          title: 'List Product',
          link: '/product',
        },
        {
          name: 'list product category',
          title: 'List Category',
          link: '/product/category',
        },
      ],
    },
    {
      name: 'course',
      title: 'Pelatihan',
      icon: 'mdiCalendarText',
      child: [
        {
          name: 'list course',
          title: 'List Pelatihan',
          link: '/course',
        },
      ],
    },
    {
      name: 'certificates',
      title: 'Certificates',
      icon: 'mdiCalendarText',
      child: [
        {
          name: 'list certificates',
          title: 'List Certificates',
          link: '/certificates',
        },
      ],
    },
    {
      name: 'settings',
      title: 'Settings',
      icon: 'mdiCogOutline',
      child: [
        {
          name: 'setting',
          title: 'Setting',
          link: '/omni/setting',
        },
        {
          name: 'store',
          title: 'Users',
          link: '/users',
        },
      ],
    },
  ])

  const onSideBarSmallClick = () => {
    setSideBarSmall(!sideBarSmall)
  }

  useEffect(() => {}, [])

  const [menupopup] = useState([
    {
      name: 'My Profile',
      title: 'My Profile',
      icon: 'mdiAccount',
      type: 'menu',
    },
    {
      name: 'Account settings',
      title: 'Account settings',
      icon: 'mdiCogOutline',
      type: 'menu',
    },
    {
      name: 'divider',
      type: 'divider',
    },
    {
      name: 'Logout',
      title: 'Logout',
      icon: 'mdiLogout',
      type: 'menu',
    },
  ])

  const buttons = [
    {
      name: 'btnok',
      title: 'Logout',
    },
    {
      name: 'btncancel',
      title: 'Cancel',
    },
  ]

  const onMenuPopUpClick = name => {
    if (name == 'Logout') {
      setShowModalLogout(true)
    }
  }

  const onDialogModalClose = name => {
    if (name == 'btnok') {
      let url = '/api/api/auth/logout'
      if (process.env.NODE_ENV === 'production') {
        url = '/api/auth/logout'
      }
      superagent
        .post(url)
        .send({})
        .then(res => {
          location.href = '/'
        })
        .catch(err => {
          console.error(err)
        })
    }
    setShowModalLogout(false)
  }

  const onSetToNormal = () => {
    setSideBarSmall(false)
  }

  let render = () => {
    return (
      <div className="flex flex-col w-screen h-screen">
        <div className="flex-none flex flex-row justify-between bg-white h-12 shadow z-30">
          <div className="flex flex-row items-center text-gray-500 ml-4">
            <Bars3Icon
              onClick={() => onSideBarSmallClick()}
              className="w-5 text-gray-600 hover:text-gray-300 cursor-pointer mr-4"
            />
            <img src={nagara} alt="Logo Nagara" width={45} height={45} />
            <div className="ml-3 font-bold">NAGARA WORKSHOP</div>
          </div>
          <PopUpMenu menu={menupopup} onClick={onMenuPopUpClick}>
            <div className="flex flex-row h-12 justify-center items-center mr-2 w-48">
              <div className="w-8">
                <img className="rounded-full" src={userprofile} />
              </div>
              <div className="mx-3 text-xs text-gray-600">{user?.name}</div>
            </div>
          </PopUpMenu>
        </div>

        <div className="grow flex flex-row justify-between overflow-y-hidden h-full">
          <SideBarMenu
            menu={menu}
            isSmall={sideBarSmall}
            onSetToNormal={onSetToNormal}
          />
          <div className="w-full overflow-auto z-10 bg-gray-100">
            <Outlet />
          </div>
        </div>

        <DialogModal
          title="Confirm"
          buttons={buttons}
          open={showModalLogout}
          onClose={onDialogModalClose}
        >
          <p className="text-sm text-gray-500">
            Are you sure want to logout from this application?
          </p>
        </DialogModal>
      </div>
    )
  }

  return render()
}
